import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logins } from '../../service/user';
import logo from '../../Image/logo.png';
import { setItemWithExpireTime } from '../../service/function';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store/useAuthStore';

const Login = ({ setShow }) => {
  const { setUserInfo, setIsLogin } = useAuthStore();
  const [email, setEmail] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setShow(false);
  }, []);
  const writeChange = (e) => {
    setEmail(e.target.value);
  };

  const onFinish = (values) => {
    logins(values.userId, values.uPassword)
      .then(function (response) {
        if (response.data) {
          // const { token, userId, uGrade } = response.data;
          const userInfo = response.data;
          setItemWithExpireTime('userInfo', userInfo, 86400 * 14); // 14d
          setUserInfo(userInfo);
          localStorage.setItem('isLogin', true);
          setIsLogin(true);

          alert('로그인 되었습니다', '확인 버튼을 눌러 주세요', 'success');
          setShow(true);
          navigate('/');
          // navigate('/', { replace: true });
        } else {
          alert(
            '아이디/비밀번호를 확인해주세요',
            '확인 버튼을 눌러 주세요',
            'success'
          );
        }
      })
      .catch(function (error) {
        alert('에러', '확인 버튼을 눌러 주세요', 'error');
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='backbg'>
      <div className='login_contain allbox'>
        <div className='login_logo'>
          <a href='/'>
            <img src={logo} />
          </a>
        </div>
        <Form
          style={{ marginTop: '40px' }}
          name='login'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            name='userId'
            required
            tooltip='아이디를 입력해주세요'
            rules={[
              {
                required: true,
                message: '아이디를 입력해주세요.',
              },
            ]}
          >
            <Input
              style={{ height: '40px' }}
              onChange={writeChange}
              prefix={<UserOutlined />}
              placeholder='아이디를 입력해주세요.'
            />
          </Form.Item>
          <Form.Item
            className='passwordwrap'
            name='uPassword'
            required
            tooltip='비밀번호를 입력해주세요'
            rules={[
              {
                required: true,
                message: '비밀번호를 입력해주세요.',
              },
              {
                pattern: new RegExp('^.{8,}$'),
                message: '최소 8자 이상으로 작성해주세요!',
              },
            ]}
          >
            <Input.Password
              autoComplete='on'
              style={{ height: '40px' }}
              prefix={<LockOutlined />}
              placeholder='비밀번호를 입력해주세요.'
            />
          </Form.Item>

          <Button className='loginbtn' type='primary' htmlType='submit'>
            로그인
          </Button>
          <Button
            className='loginbtn'
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '0px' }}
          >
            <Link to={'/Signup'}>회원가입</Link>
          </Button>
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 15,
            }}
          ></Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
