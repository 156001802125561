import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Crumb1, Crumb2 } from '../../components_btn/Breadcrums';
import Pagetitle from '../../components_btn/Pagetitle';
import Mainnotice from '../../Main/Mainnotice';
import FooterNavi from '../FooterNavi';
import Centerbox from './Centerbox';
import FAQ from './FAQ';
import Notice from './Notice';
import QNA from './QNA';
function Center({ setBack }) {
    const location = useLocation();
    const pathnum1 = location.pathname.split('/')[1];
    const pathnum2 = location.pathname.split('/')[2];

    useEffect(() => {
        setBack(true);
    }, []);
    return (
        <div className="main shop displaybox">
            <div className="shop_div">
                <div className="shop_sort">
                    <FooterNavi num1={pathnum1} num2={pathnum2} />
                </div>
                <div className="shop_content">
                    {pathnum2 ? <Crumb2 num1={pathnum1} num2={pathnum2} /> : <Crumb1 num={pathnum1} />}
                    {pathnum2 === 'notice' ? (
                        <Notice />
                    ) : pathnum2 === 'FAQ' ? (
                        <FAQ />
                    ) : pathnum2 === 'qna' ? (
                        <QNA />
                    ) : (
                        <div>
                            <Pagetitle value="고객센터" svalue={'서비스 이용관련 문의사항을 해결해드립니다'} />
                            <Centerbox />
                            <Mainnotice />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Center;
