import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import {
  addMainCate,
  addCates,
  dMainCate,
  dSubCate,
  getMainCate,
} from '../../../service/function';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  fetchDataDelete,
  fetchDataPost,
  fetchDataPut,
  reactQueryFn,
} from '../../../service/instance';
import expandIcon from '../../../Image/icons/expand.png';
import expandedIcon from '../../../Image/icons/expanded.png';
import { CATEGORY } from '../../../utils/constant';
import { useQuery } from 'react-query';
import { groupByCategory } from '../../../utils/function';
import useFlatCategorys from '../../../utils/useFlatCategorys';

/**
 * @typedef {Object} SubCategory
 * @property {number} id // subcategory id
 * @property {number} categoryId
 * @property {string} subcategory
 */

/**
 *
 * @param {Object} props - 컴포넌트의 props
 * @param {Function} props.hideCategoryInput - 카테고리 입력을 숨기는 함수
 * @param {{ id: number, category: string }} props.item
 */
function AddInputComponent({ item, hideCategoryInput, R_addSubcategory }) {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const resetValue = () => {
    setValue('');
  };

  const cancelController = (label) => {
    hideCategoryInput(label);
    resetValue();
  };

  const api_addSubCategory = async (categoryId, inputValue) => {
    const payload = { categoryId, subcategory: inputValue };
    const response = await fetchDataPost('/api/subcategory', payload);
    console.log('🚀 ~ constapi_addSubCategory= ~ response:', response);
    if (!response) return;

    /** @type {SubCategory} */
    const subCategory = response.data;
    R_addSubcategory(subCategory);
    resetValue();
  };

  const handleAddCategory = (categoryId, inputValue) => {
    api_addSubCategory(categoryId, inputValue);
  };

  return (
    <div className='relative'>
      <Input
        placeholder='추가할 카테고리를 입력하세요'
        onChange={onChange}
        value={value}
      />
      <div className='absolute top-1 right-4 flex gap-2'>
        <div
          className='cursor-pointer'
          onClick={() => handleAddCategory(item.id, value)}
        >
          추가
        </div>
        <div
          className='text-red-500 cursor-pointer'
          onClick={() => cancelController(item.category)}
        >
          취소
        </div>
      </div>
    </div>
  );
}

/**
 *
 * @param {Object} props
 * @param {SubCategory} props.item
 * @param {Function} props.closeInput
 * @returns
 */
function UpdateInputComponent({
  item,
  closeInput,
  R_UpdateSubcategory,
  R_deleteSubcategory,
}) {
  const [value, setValue] = useState(item.subcategory);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const api_updateSubCategory = async (inputValue) => {
    const subcategoryId = item.id;
    const payload = { subcategory: inputValue };
    const response = await fetchDataPut(
      `/api/subcategory/${subcategoryId}`,
      payload
    );
    if (!response) return;

    const newSubCategory = {
      ...item,
      subcategory: inputValue,
    };
    R_UpdateSubcategory(newSubCategory);
    closeInput();
  };

  const api_deleteSubCategory = async () => {
    const subcategoryId = item.id;
    const response = await fetchDataDelete(`/api/subcategory/${subcategoryId}`);

    if (response.status === 409) {
      const res = await response.json();
      alert(res.message);
    } else if (response.status === 200) {
      R_deleteSubcategory(item);
      closeInput();
    }
  };

  return (
    <div className='relative w-full'>
      <Input onChange={onChange} value={value} />
      <div className='absolute top-1 right-4 flex gap-2'>
        <div
          className='cursor-pointer'
          onClick={() => api_updateSubCategory(value)}
        >
          변경
        </div>
        <div className='cursor-pointer' onClick={api_deleteSubCategory}>
          삭제
        </div>
        <div className='text-red-500 cursor-pointer' onClick={closeInput}>
          취소
        </div>
      </div>
    </div>
  );
}

function SubCategoryItem({ item, R_UpdateSubcategory, R_deleteSubcategory }) {
  const [inviewInput, setInviewInput] = useState(false);

  const showInput = () => {
    setInviewInput(true);
  };

  const closeInput = () => {
    setInviewInput(false);
  };

  return (
    <li className='flex justify-between'>
      {inviewInput ? (
        <UpdateInputComponent
          item={item}
          closeInput={closeInput}
          R_UpdateSubcategory={R_UpdateSubcategory}
          R_deleteSubcategory={R_deleteSubcategory}
        />
      ) : (
        <div className='text-base'>{item.subcategory}</div>
      )}

      {!inviewInput && (
        <div>
          <Button size='small' onClick={showInput}>
            수정
          </Button>
        </div>
      )}
    </li>
  );
}

function CategoryManage() {
  const initSubCategory = CATEGORY.map((item) => ({
    ...item,
    isInview: false,
    isInviewInput: false,
  }));
  const [inviewSubCategory, setInviewSubCategory] = useState(initSubCategory);

  const { flatCategorys: initFlatCategorys } = useFlatCategorys();
  const [flatCategorys, setFlatCategorys] = useState(initFlatCategorys);

  useEffect(() => {
    if (initFlatCategorys) {
      setFlatCategorys(initFlatCategorys);
    }
  }, [initFlatCategorys]);

  const showCategoryInput = (e, label) => {
    e.stopPropagation();
    const newInviewSubCategory = [...inviewSubCategory].map((item) =>
      item.category === label
        ? { ...item, isInview: true, isInviewInput: true }
        : item
    );
    setInviewSubCategory(newInviewSubCategory);
  };
  const hideCategoryInput = (label) => {
    // e.stopPropagation();
    const newInviewSubCategory = [...inviewSubCategory].map((item) =>
      item.category === label ? { ...item, isInviewInput: false } : item
    );
    setInviewSubCategory(newInviewSubCategory);
  };

  const showSubCategory = (label) => {
    const newInviewSubCategory = [...inviewSubCategory].map((item) =>
      item.category === label ? { ...item, isInview: true } : item
    );
    setInviewSubCategory(newInviewSubCategory);
  };

  const hideSubCategory = (label) => {
    const newInviewSubCategory = [...inviewSubCategory].map((item) =>
      item.category === label ? { ...item, isInview: false } : item
    );
    setInviewSubCategory(newInviewSubCategory);
  };

  /**
   * @param {SubCategory} subCategory
   */
  const R_addSubcategory = (subCategory) => {
    // Category label 값과 subcategory label 값을 받아 배열에 넣어준다
    const newFlatCategorys = { ...flatCategorys };
    const categoryLabel = CATEGORY.find(
      (item) => item.id === subCategory.categoryId
    ).category;
    newFlatCategorys[categoryLabel].push(subCategory);
    setFlatCategorys(newFlatCategorys);
  };

  /**
   * @param {SubCategory} subCategory
   */
  const R_UpdateSubcategory = (subCategory) => {
    const newFlatCategorys = { ...flatCategorys };
    const categoryLabel = CATEGORY.find(
      (item) => item.id === subCategory.categoryId
    ).category;
    const subCategoryIndex = newFlatCategorys[categoryLabel].findIndex(
      (item) => item.id === subCategory.id
    );
    newFlatCategorys[categoryLabel][subCategoryIndex] = subCategory;
    setFlatCategorys(newFlatCategorys);
  };

  /**
   * @param {SubCategory} subCategory
   */
  const R_deleteSubcategory = (subCategory) => {
    const newFlatCategorys = { ...flatCategorys };
    const categoryLabel = CATEGORY.find(
      (item) => item.id === subCategory.categoryId
    ).category;
    const newSubCategory = newFlatCategorys[categoryLabel].filter(
      (item) => item.id !== subCategory.id
    );
    newFlatCategorys[categoryLabel] = newSubCategory;
    setFlatCategorys(newFlatCategorys);
  };

  return (
    <div className=''>
      <div className='flex flex-col gap-2'>
        <ul className='flex flex-col gap-[2px]'>
          {CATEGORY.map((item, index) => (
            <li
              key={`관리자-카테고리-${index}`}
              className='p-3 border border-solid border-gray-300 rounded-md'
            >
              <div
                className='flex justify-between cursor-pointer'
                onClick={() =>
                  inviewSubCategory[index]?.isInview
                    ? hideSubCategory(item.category)
                    : showSubCategory(item.category)
                }
              >
                <div className='flex gap-2 items-center'>
                  <div className='w-[15px] h-[15px]'>
                    <img
                      className='w-full h-full'
                      alt='expand-btn'
                      width={30}
                      height={30}
                      src={
                        inviewSubCategory[index]?.isInview
                          ? expandedIcon
                          : expandIcon
                      }
                    />
                  </div>
                  <div>{item?.category}</div>
                </div>
                <div>
                  <Button onClick={(e) => showCategoryInput(e, item.category)}>
                    카테고리 추가
                  </Button>
                </div>
              </div>
              {inviewSubCategory[index]?.isInview && (
                <div className='flex flex-col gap-[5px] px-[20px] py-[10px]'>
                  <div>
                    {inviewSubCategory[index]?.isInviewInput && (
                      <AddInputComponent
                        item={item}
                        hideCategoryInput={hideCategoryInput}
                        R_addSubcategory={R_addSubcategory}
                      />
                    )}
                  </div>
                  <ul className='flex flex-col gap-2'>
                    {flatCategorys[item.category] &&
                      flatCategorys[item.category].map((item, index) => (
                        <SubCategoryItem
                          key={`관리자-sub카테고리-${index}`}
                          item={item}
                          R_UpdateSubcategory={R_UpdateSubcategory}
                          R_deleteSubcategory={R_deleteSubcategory}
                        />
                      ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CategoryManage;
