import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Approuter from './Approuter';
import { PopupProvider } from './PopupContext';
import { checkExpireTimeAndLogout } from './service/function';
import './style/index.css';
import './style/reset.css';
import './style/style.css';
import Swal from 'sweetalert2';

const queryClient = new QueryClient();
function App() {
  useEffect(() => {
    checkExpireTimeAndLogout();
  }, []);

  //   임시 alert
  // useEffect(() => {
  //   Swal.fire({
  //     title: '현재 로봇몰은 개발 중입니다.',
  //     text: '(주)니즈로봇 개발팀 드림',
  //     icon: 'info',
  //   });
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <PopupProvider>
        <Approuter />
      </PopupProvider>
    </QueryClientProvider>
  );
}

export default App;
