import Slider from 'react-slick';
import { truncateString } from '../../utils/function';
import { useNavigate } from 'react-router-dom';
import { fetchDataPut } from '../../service/instance';
import { useRef, useState } from 'react';

function NzrProductCarousel({ data, mainControl }) {
  console.log('🚀 ~ NzrProductCarousel ~ mainControl:', mainControl);
  // console.log(data);
  const mainControlRef = useRef(mainControl);

  const navigate = useNavigate();
  const navigateProductDetail = (product) => {
    const category = product.category;
    const subCategory = product.subcategory;
    const productId = product.id;
    navigate(`/shop/${category}/${subCategory}/${productId}`);
  };

  // NZR Signature 변경 기능 미완성
  // const onChangeMainControl = (key, value) => {
  //   mainControlRef.current[key] = value;
  // };

  // async function putNzrSignature() {
  //   const newMainControl = mainControlRef.current;

  //   const response = await fetchDataPut('/api/nzr-signature', newMainControl);
  //   if (response.status === 200) {
  //     console.log('🚀 ~ putNzrSignature ~ response:', response);
  //     window.location.reload();
  //     alert('상품 변경 완료, 변경내용은 새로고침 하면 반영됩니다');
  //   }
  // }

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <div className='w-full'>
      <Slider {...settings}>
        {data &&
          data.map((product) => (
            <div className='relative w-full border border-solid border-[#4b6cb7] p-[6px]'>
              {/* <div className='absolute top-[2%] right-[5%] text-blue-500'>
                변경
              </div> */}
              <div>
                <span>{product.brand}</span>
                <span>[상품코드: {product.id}]</span>
              </div>
              <div
                className='w-full h-[200px] cursor-pointer'
                onDoubleClick={() => navigateProductDetail(product)}
              >
                <img
                  src={product.img1}
                  alt='product 이미지'
                  className='w-full h-full'
                  width={200}
                  height={150}
                />
              </div>
              <div>{truncateString(product.pName, 19)}</div>
              <div className='min-h-[20px]'>
                <span>{product.inch}</span>
                <span>{product.material}</span>
                <span>{product.color}</span>
              </div>
              <div className='min-h-[20px]'>
                {truncateString(product.pDetail, 19)}
              </div>
              <div className='flex justify-between'>
                <div className='flex gap-2'>
                  <div className='line-through'>{product.pCost}</div>
                  <div className='bg-[#0b255b] text-white'>
                    {product.dcrate}%
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='bg-[#0b255b] text-white'>m.o.q</div>
                  <div>{product.moq}</div>
                </div>
              </div>
              <div className='flex justify-between'>
                <div className='text-[#0b255b] font-bold text-xl'>
                  {product.pPrice}원
                </div>
                <div className='flex gap-2'>
                  <div>준비</div>
                  <div>약 {product.prepare}일</div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default NzrProductCarousel;
