import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { groupByCategory } from './function';
import { reactQueryFn } from '../service/instance';

/**
 * @typedef {Object} subcategory
 * @property {number} categoryId - 카테고리 아이디
 * @property {number} id - 세부 카테고리 아이디
 * @property {string} subcategory - 세부 카테고리 이름
 */

/**
 * @typedef {Object} _flatCategorys
 * @property {subcategory[]} AGV - AGV 관련 항목들
 * @property {subcategory[]} board - 보드 관련 항목들
 * @property {subcategory[]} SENSOR - 센서 관련 항목들
 * @property {subcategory[]} IOT - IoT 관련 항목들
 */

/**
 * @returns {{ flatCategorys: _flatCategorys }} - flatCategorys 객체를 반환하는 커스텀 훅
 */
const useFlatCategorys = () => {
    const [flatCategorys, setFlatCategorys] = useState({});

    // subCategory fetch
    const { data } = useQuery({
        queryKey: ['category-depth2'],
        queryFn: () => reactQueryFn('api/category?depth=2'),
    });

    useEffect(() => {
        if (data) {
            const groupCategory = groupByCategory(data);
            setFlatCategorys(groupCategory);
        }
    }, [data]);

    return { flatCategorys, data };
};

export default useFlatCategorys;
