import { useEffect, useRef, useState } from 'react';
import { getevent } from '../../../service/product';
import { Button, Modal } from 'antd';
import EditEventModal from './EditEventModal';
import { Link } from 'react-router-dom';
import { fetchDataDelete } from '../../../service/instance';
import Swal from 'sweetalert2';

/**
 * @typedef {Object} Event
 * @property {string} id
 * @property {string} title
 * @property {string} category
 * @property {string} thumb // 썸네일
 * @property {string} content
 * @property {string} startday
 * @property {string} lastday
 * @property {string} writer
 * @property {string} updatetime
 * @returns
 */

/**
 * @template T
 * @typedef {ReturnType<typeof import("react").useState<T>>} useState
 */

const EditEvent = () => {
  /** @type {useState<Event[]>} */
  const [events, setEvents] = useState(null);

  useEffect(() => {
    getevent().then((response) => {
      setEvents(response.data);
    });
  }, []);

  const [targetEvent, setTargetEvent] = useState(null);
  const editBtnController = (event) => {
    setTargetEvent(event);
    showModal();
  };

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const R_updateEvent = (updatedEvent) => {
    const newEvent = [...events];
    const _newEvent = newEvent.map((event) => {
      if (event.id === updatedEvent.id) {
        return updatedEvent;
      } else {
        return event;
      }
    });
    setEvents(_newEvent);
  };

  const R_deleteEvent = (eventId) => {
    const newEvent = [...events];
    const _newEvent = newEvent.filter((event) => event.id !== eventId);
    setEvents(_newEvent);
  };

  const api_deleteEvent = async (eventId) => {
    return await fetchDataDelete(`/api/event/${eventId}`);
  };

  const deleteEvent = async (eventId) => {
    const swalResult = await Swal.fire({
      title: '정말 삭제하시겠습니까?',
      // icon: 'warning',
      showCancelButton: true,
      cancelButtonText: '취소',
      confirmButtonText: '삭제',
    });
    if (!swalResult.isConfirmed) return;

    const response = await api_deleteEvent(eventId);
    if (response.status === 200) {
      R_deleteEvent(eventId);
    }
  };

  return (
    <div>
      <div>
        <ul className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4'>
          {events &&
            events.map((event) => (
              <li key={`editEvent-${event.id}`} className=''>
                <div className='w-full h-[200px]'>
                  <Link to={`/Event/event?&productid=${event.id}`}>
                    <img
                      src={event.thumb}
                      alt='event'
                      className='w-full h-full'
                      // width={400}
                      height={200}
                    />
                  </Link>
                </div>
                <div className='flex justify-between'>
                  <div>
                    <div>{event.title}</div>
                    <div>
                      {event.startday.split(' ')[0]} ~{' '}
                      {event.lastday.split(' ')[0]}
                    </div>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <Button onClick={() => editBtnController(event)}>
                      수정
                    </Button>
                    <Button onClick={() => deleteEvent(event.id)}>삭제</Button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <EditEventModal
        event={targetEvent}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        R_updateEvent={R_updateEvent}
      />
    </div>
  );
};

export default EditEvent;
