import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Pagetitle from '../components_btn/Pagetitle';
import { getiot } from '../../service/product';
import { Mainlinkto } from './Mainfunction';
import { Carousel, Button } from 'antd';
import { actionButStyle, Settings2 } from './Mainfunction';
import { getIotProductData, getProductData } from '../../utils/function';
import ProductCarousel from './ProductCarousel';
function Mainiot() {
  const carouselRef = useRef();
  const [iotlist, setIotlist] = useState([]);
  useEffect(() => {
    async function init() {
      const iotProducts = await getIotProductData('latest');
      setIotlist(iotProducts);
    }
    init();
  }, []);

  return (
    <>
      <div className='iotallcon'>
        <Pagetitle value={'최신 IOT'} main={true} />
        {/* <Mainlinkto num={3} value={'iot'}  /> */}

        <ProductCarousel data={iotlist} />
      </div>
    </>
  );
}

export default Mainiot;
