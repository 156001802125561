import axios from "axios";
import { getLocalStorageData, setItemWithExpireTime } from "./function";


export const allurl = 'https://needsrobot.com' // 운영계 API 서버 url
// export const allurl = 'http://localhost:3001' // local API 서버 url

const instance = axios.create({
  baseURL: allurl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true
});

const instanceWithToken = axios.create({
  baseURL: allurl,
  headers: {
    "Content-Type": "application/json",
    // "Authorization": `Bearer ${token}`
  },
  withCredentials: true
});




instanceWithToken.interceptors.request.use(
  function (config) {

    const userInfo = localStorage.getItem('userInfo');
    const accessToken = userInfo ? JSON.parse(userInfo).value.token : '';

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instanceWithToken.interceptors.response.use(
  async function (response) {
    return response
  },

  async function (error) {
    // 2xx 외의 범위에 있는 상태 코드인 경우
    // console.log(error)
    const {
      config,
      response: { status, data },
    } = error

    const errorCode = data?.error?.code;
    if (status === 401 && errorCode === 'TOKEN_EXPIRED') {
      try {
        const response = await instance.post('/api/auth/refresh-token')

        if (response.status === 200) {
          console.log('🚀 ~ response:', response)

          // 새로 발급받은 토큰을 스토리지에 저장
          const { accessToken } = response.data;
          const userInfo = getLocalStorageData('userInfo');
          const userInfoValue = userInfo.value;
          const newUserInfo = { ...userInfoValue, token: accessToken }

          setItemWithExpireTime('userInfo', newUserInfo, 86400 * 14) // 14d

          config.headers['Authorization'] = `Bearer ${accessToken}`
          // 토큰 갱신 성공. API 재요청
          return instance(config)
        } else {
          // logoutAndHome() // 전체 프로세스 정해지면 추가
        }
      } catch (err) {
        console.error(err)
        const { response: { status, data } } = err;
        if (status === 400 || status === 401) {
          alert("세션이 만료되었습니다. 다시 로그인해 주시기 바랍니다.")
          window.location.href = '/login';
        }
      }

      return Promise.reject(error)
    }
  }
)



export { instance, instanceWithToken }