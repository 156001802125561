import { create } from "zustand";

/**
 * @typedef {Object} UserInfo
 * @property {string} userId
 * @property {string} token
 * @property {string} uName
 * @property {string} uGrade
 * @property {string} uPhone
 */

/**
 * @typedef {Object} AuthStore
 * @property {boolean} isLogin
 * @property {function(boolean): void} setIsLogin
 * @property {UserInfo} userInfo
 * @property {function(UserInfo): void} setUserInfo
 */

const userInfo = {
  userId: '',
  token: '',
  uName: '',
  uGrade: '',
  uPhone: '',
};

const getLogin = localStorage.getItem("isLogin");
const getUserInfo = localStorage.getItem("userInfo");

/**
 * @type {() => AuthStore}
 */
export const useAuthStore = create((set) => ({
  isLogin: getLogin ? JSON.parse(getLogin) : false,
  setIsLogin: (payload) => set({ isLogin: payload }),

  userInfo: getUserInfo ? JSON.parse(getUserInfo)?.value : userInfo,
  setUserInfo: (payload) => set({ userInfo: payload }),
}))