import {
  ExclamationCircleFilled,
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import agv from './Image/agv.png';
import board from './Image/board.png';
import event from './Image/event.png';
import iot from './Image/ito.png';
import purchase from './Image/purchase.png';
import sensor from './Image/sensor.png';
import { useAuthStore } from './store/useAuthStore';
import { MAIN_CATEGORY } from './utils/constant';
const { confirm } = Modal;

// eslint-disable-next-line react/prop-types
const Header = ({ sb, setSb }) => {
  const userInfo = useAuthStore((state) => state.userInfo);
  const isLogin = useAuthStore((state) => state.isLogin);

  const toggleModalController = (e) => {
    e.stopPropagation();
    setModalVisable((prev) => !prev);
  };

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [searchon, setSearchon] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsHeaderFixed(true);
      } else if (window.scrollY <= 100) {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const newItems = MAIN_CATEGORY.map((item) => {
    const key = `메인카테고리-${item.category}`;

    return {
      label: (
        <Link key={key} className='menuli' to={item.link}>
          <div className='menuicon'>
            <img
              src={
                item.category === 'AGV'
                  ? agv
                  : item.category === 'board'
                    ? board
                    : item.category === 'SENSOR'
                      ? sensor
                      : item.category === 'IOT'
                        ? iot
                        : item.category === 'EVENT'
                          ? event
                          : purchase
              }
            />
          </div>
          <div>{item.label}</div>
        </Link>
      ),
      key,
      path: key,
    };
  });
  const location = useLocation();
  const pathKeys = location.pathname.split('/').filter(Boolean);

  const showConfirm = () => {
    confirm({
      title: '로그아웃 하시겠습니까?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        localStorage.removeItem('isLogin');
        localStorage.removeItem('userInfo');
        window.location.href = '/';
      },
      onCancel() {},
    });
  };
  const items = [
    userInfo && userInfo.uGrade == 1
      ? {
          label: (
            <div>
              <Link to='/Admin'>관리자 페이지</Link>
            </div>
          ),
          key: '4',
        }
      : '',
    {
      label: (
        <Link
          to={!isLogin ? '/Login' : '/Mypage'}
          className='function_head_box login'
        >
          마이 페이지
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <div className='function_head_box '>
          <Link to='/customer-service'>고객센터</Link>
        </div>
      ),
      key: '1',
    },
    {
      label: !isLogin ? (
        <div>
          <Link to='/Login'>로그인</Link>
        </div>
      ) : (
        <div onClick={showConfirm}>
          <a>로그아웃</a>
        </div>
      ),
      key: '3',
    },
  ];

  return (
    <div className={isHeaderFixed ? 'header fix' : 'header '}>
      {/* <div className='header_bottom_bg'>
 </div> */}
      <div className='header_wrap displaybox relative'>
        {/* temp 안내문구 */}
        <div className='absolute top-1/2 left-[12%] text-white'>
          <div className='typing-demo'>현재 로봇몰은 개발 중입니다.</div>
        </div>
        <div className='logowrap'>
          <Link to='/' className='logo'>
            <img src={'https://ifh.cc/g/bNC3w6.png'} alt='logo' />
          </Link>
        </div>
        <Menu
          className='navigation '
          selectedKeys={[...pathKeys, `/${pathKeys[0]}`]}
          mode='horizontal'
          items={newItems}
        />
        <ul className='function_head'>
          <div className='function_head_box login'></div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <UserOutlined />
          </Dropdown>

          <Link to={'/needs'} className='function_head_box login'>
            <HeartOutlined />
          </Link>
          <Link to={'/Cart'} className='function_head_box cart'>
            <ShoppingCartOutlined />
          </Link>
        </ul>
      </div>
      {/* <div className='header_b'>

  </div> */}
    </div>
  );
};
export default Header;
