import { allurl, instance, instanceWithToken } from "./baseAxiosInstance";

const reactQueryFn = (url) => {
  return fetch(`${allurl}/${url}`).then((res) => res.json());
}

const reactQueryFnWithParams = (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  const fullUrl = `${allurl}/${url}?${queryString}`;

  return fetch(fullUrl).then((res) => res.json());
}


const fetchData = async (url, params) => {
  try {
    const response = await instance.get(url, {
      params,
    });
    return response;
  } catch (err) {
    console.log(`오류: ${err}`);
    return err;
  }
};

const fetchDataWithToken = async (url, params) => {
  try {
    const response = await instanceWithToken.get(url, {
      params,
    });
    return response;
  } catch (err) {
    console.log(`오류: ${err}`);
    return err;
  }
}

const fetchDataPost = async (url, body) => {
  try {
    const response = await instanceWithToken.post(url, body);
    return response;
  } catch (err) {
    console.error(`오류: ${err}`);
    return err;
  }
};

const fetchDataPut = async (url, body) => {
  try {
    const response = await instanceWithToken.put(url, body);
    return response;
  } catch (err) {
    console.error(`오류: ${err}`);
    return err;
  }
};

const fetchDataDelete = async (url, body, params) => {
  try {
    const response = await instanceWithToken.delete(url, {
      data: body,
      params
    });
    return response;
  } catch (err) {
    console.error(`오류: ${err}`);
    return err;
  }
};

const dData = async (carriers, num) => {
  try {
    const response = await instance.get(`https://apis.tracker.delivery/carriers/${carriers}/tracks/${num}`);
    return response;
  } catch (err) {
    console.log(`오류: ${err}`);
    return err;
  }
};
export { reactQueryFn, reactQueryFnWithParams, fetchData, fetchDataWithToken, dData, fetchDataPost, fetchDataPut, fetchDataDelete };