import { Button, Input, Pagination, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fetchData, fetchDataPut } from '../../../service/instance';
import {
  categoryOptions,
  colorOption,
  materialOption,
} from '../../../service/options';
import { CATEGORY, EDITPRODUCT_TABLE_COLUMNS } from '../../../utils/constant';
import useFlatCategorys from '../../../utils/useFlatCategorys';

function EditProduct() {
  const [data, setData] = useState(null);
  console.log('🚀 ~ EditProduct ~ data:', data);
  const [isEditing, setIsEditing] = useState(null);
  const [category, setCategory] = useState({
    id: null,
    // value: null,
  });

  const productInfoRef = useRef(null);
  const onChangeProductInfo = (key, value) => {
    productInfoRef.current = {
      ...productInfoRef.current,
      [key]: value,
    };
  };

  const onChangeCategory = (categoryId) => {
    const newCategory = { id: categoryId };
    setCategory(newCategory);
    onChangeProductInfo('categoryId', categoryId);
  };

  const getInitIsEditingState = (data) => {
    const initIsEditState = data.map((item) => {
      return {
        id: item.id,
        isEditing: false,
      };
    });
    return initIsEditState;
  };

  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);

  async function paginationFetch(cate, pName) {
    const params = {
      cate: cate ? cate : '',
      pName: pName ? pName : '',
      page,
      size,
    };
    const response = await fetchData('/api/products', params);
    const {
      data,
      page: _page,
      size: _size,
      totalElements,
      totalPages,
    } = response.data;
    setTotalElements(totalElements);
    setData(data);
    const initIsEdit = getInitIsEditingState(data);
    setIsEditing(initIsEdit);
  }

  useEffect(() => {
    paginationFetch();
  }, [page, size]);

  // 검색 Controller
  const searchInputRef = useRef(null);
  const onChangeSearchInput = (e) => {
    searchInputRef.current = e.currentTarget.value;
  };
  const searchSelectRef = useRef(null);
  const onChangeSearchCategory = (categoryId) => {
    console.log('🚀 ~ onChangeSearchCategory ~ value:', categoryId);
    const _category = CATEGORY.find((item) => item.id === categoryId).category;
    searchSelectRef.current = _category;
  };

  const onClickSearch = () => {
    const category = searchSelectRef.current;
    const pNameValue = searchInputRef.current;
    paginationFetch(category, pNameValue);
  };

  const activeEditingController = (item) => {
    activeEditing(item.id);
    productInfoRef.current = item;
  };

  const activeEditing = (id) => {
    const newEdit = [...isEditing];
    const updatedEdit = newEdit.map((item) => {
      if (item.id === id) {
        item.isEditing = true;
      }
      return item;
    });
    setIsEditing(updatedEdit);
  };

  const disableEditing = (id) => {
    const newEdit = [...isEditing];
    const updatedEdit = newEdit.map((item) => {
      if (item.id === id) {
        item.isEditing = false;
      }
      return item;
    });
    setIsEditing(updatedEdit);
  };

  // subcategory fetch
  const { flatCategorys } = useFlatCategorys();
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  useEffect(() => {
    if (flatCategorys && category.id) {
      const categoryLabel = CATEGORY.find(
        (item) => item.id === category.id
      )?.category;
      const subcategoryOptions =
        flatCategorys &&
        categoryLabel &&
        flatCategorys[categoryLabel].map((item) => {
          return {
            value: item.id,
            label: item.subcategory,
          };
        });
      setSubcategoryOptions(subcategoryOptions);
    }
  }, [flatCategorys, category.id]);

  //   제품 업데이트
  const handleSave = async (productId) => {
    if (!productInfoRef.current) return;
    if (
      !productInfoRef.current.id ||
      !productInfoRef.current.pName ||
      !productInfoRef.current.pCost ||
      !productInfoRef.current.moq ||
      !productInfoRef.current.prepare ||
      !productInfoRef.current.inch ||
      !productInfoRef.current.material ||
      !productInfoRef.current.brand ||
      !productInfoRef.current.color
    ) {
      return alert('모든 항목을 입력해주세요.');
    }

    const payload = {
      categoryId: productInfoRef.current.categoryId,
      subcategoryId: productInfoRef.current.subcategoryId,
      pName: productInfoRef.current.pName,
      pquantity: productInfoRef.current.pquantity,
      pCost: productInfoRef.current.pCost,
      dcrate: productInfoRef.current.dcrate,
      moq: productInfoRef.current.moq,
      prepare: productInfoRef.current.prepare,
      inch: productInfoRef.current.inch,
      color: productInfoRef.current.color,
      material: productInfoRef.current.material,
      brand: productInfoRef.current.brand,
    };
    const response = await fetchDataPut(`/api/products/${productId}`, payload);
    if (response.status === 200) {
      alert('제품이 업데이트 되었습니다.');
      disableEditing(productId);
      R_updateData(productInfoRef.current);
    }
  };

  const R_updateData = (updatedData) => {
    const category = CATEGORY.find(
      (item) => item.id === updatedData.categoryId
    ).category;
    const subCategory = flatCategorys[category].find(
      (item) => item.id === updatedData.subcategoryId
    ).subcategory;
    const newUpdatedData = {
      ...updatedData,
      category: category,
      subcategory: subCategory,
    };
    const updated = data.map((item) => {
      if (item.id === updatedData.id) {
        return newUpdatedData;
      }
      return item;
    });
    setData(updated);
  };

  return (
    <div className='w-full h-full flex flex-col gap-4'>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <div className='min-w-[100px]'>
            <Select
              onChange={onChangeSearchCategory}
              options={categoryOptions}
              className='w-full'
            />
          </div>
          <div>
            <Input onChange={onChangeSearchInput} />
          </div>
          <div>
            <Button onClick={onClickSearch}>검색</Button>
          </div>
        </div>
        <div className='w-full h-[50vh] overflow-auto'>
          <table className='w-full min-w-[1000px] border border-solid border-gray-400'>
            <thead>
              {EDITPRODUCT_TABLE_COLUMNS.map((column) => (
                <th
                  // key={`editproduct-table-columns-${column.value}`}
                  className='border-r border-solid border-gray-400'
                  style={{
                    width: column.label === '제품명' ? '25%' : 'auto',
                    minWidth: column.label === '제품명' ? '25%' : 'auto',
                  }}
                >
                  {column.label}
                </th>
              ))}
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr
                    //   key={`editproduct-table-rows-${item.id}`}
                    className=' border-t border-solid border-gray-400'
                  >
                    {EDITPRODUCT_TABLE_COLUMNS.map((column) => (
                      <td
                        //   key={`editproduct-table-${item.id}-${column.value}`}
                        className='min-w-[100px] text-center align-middle border-r border-solid border-gray-400'
                        style={{
                          minWidth:
                            column.label === EDITPRODUCT_TABLE_COLUMNS[0].label
                              ? '30px'
                              : '100px',
                        }}
                      >
                        {column.label !== 'operation' &&
                          isEditing &&
                          isEditing[index]?.isEditing && (
                            <div className='flex items-center justify-center'>
                              {(column.label === '제품명' ||
                                column.label === '회사' ||
                                column.label === '가격') && (
                                <input
                                  className='w-full text-center border-b border-solid border-blue-500'
                                  defaultValue={item[column.value]}
                                  onChange={(e) =>
                                    onChangeProductInfo(
                                      column.value,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {(column.label === '재고' ||
                                column.label === '인치' ||
                                column.label === 'm.o.q' ||
                                column.label === '준비기간' ||
                                column.label === '할인율') && (
                                <input
                                  type='number'
                                  className='w-full text-center border-b border-solid border-blue-500'
                                  defaultValue={item[column.value]}
                                  onChange={(e) =>
                                    onChangeProductInfo(
                                      column.value,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {column.label === '카테고리' && (
                                <Select
                                  defaultValue={item[column.value]}
                                  options={categoryOptions}
                                  onChange={onChangeCategory}
                                  className='w-full h-[22px]'
                                />
                              )}
                              {column.label === '세부 카테고리' && (
                                <Select
                                  defaultValue={item[column.value]}
                                  options={subcategoryOptions}
                                  onChange={(value) =>
                                    onChangeProductInfo('subcategoryId', value)
                                  }
                                  className='w-full h-[22px]'
                                />
                              )}
                              {column.label === '소재' && (
                                <Select
                                  defaultValue={item[column.value]}
                                  options={materialOption}
                                  onChange={(value) =>
                                    onChangeProductInfo(column.value, value)
                                  }
                                  className='w-full h-[22px]'
                                />
                              )}
                              {column.label === '색상' && (
                                <Select
                                  defaultValue={item[column.value]}
                                  options={colorOption}
                                  onChange={(value) =>
                                    onChangeProductInfo(column.value, value)
                                  }
                                  className='w-full h-[22px]'
                                />
                              )}
                              {column.label ===
                                EDITPRODUCT_TABLE_COLUMNS[0].label && (
                                <div className='w-full flex justify-center'>
                                  {item[column.value]}
                                </div>
                              )}
                            </div>
                          )}
                        {column.label !== 'operation' &&
                          isEditing &&
                          !isEditing[index]?.isEditing && (
                            <div className='text-center'>
                              {item[column.value]}
                            </div>
                          )}

                        {column.label === 'operation' && (
                          <>
                            {isEditing && isEditing[index]?.isEditing ? (
                              <div className='flex gap-1 justify-center items-center'>
                                <div
                                  className='text-blue-500 cursor-pointer'
                                  onClick={() => handleSave(item.id)}
                                >
                                  Save
                                </div>
                                <div
                                  className='text-blue-500 cursor-pointer'
                                  onClick={() => disableEditing(item.id)}
                                >
                                  Cancel
                                </div>
                              </div>
                            ) : (
                              <span
                                className='text-blue-500 cursor-pointer'
                                onClick={() => activeEditingController(item)}
                              >
                                Edit
                              </span>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ justifyItems: 'right' }}>
        <Pagination
          defaultCurrent={1}
          total={totalElements}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(page, size) => setSize(size)}
        />
      </div>
    </div>
  );
}

export default EditProduct;
