
// const CATEGORY = [
//   { label: 'Robot', value: 'robot' },
//   { label: 'Smart farm', value: 'smartfarm' },
//   { label: 'Sensor', value: 'sensor' },
//   { label: 'Iot', value: 'iot' },
//   { label: 'Event', value: 'event' }
// ];


// export const MAIN_CATEGORY = [
//   {
//     "id": 1,
//     "category": "IOT",
//     "label": "IoT",
//   },
//   {
//     "id": 2,
//     "category": "SENSOR",
//     "label": "Sensor",
//   },
//   {
//     "id": 3,
//     "category": "board",
//     "label": "Robot",
//   },
//   {
//     "id": 4,
//     "category": "AGV",
//     "label": "Smart farm",
//   },
//   {
//     "id": 152,
//     "category": "EVENT",
//     "label": "Event",
//   }
// ];

export const MAIN_CATEGORY = [
  {
    "id": 1,
    "category": "AGV",
    "label": "AGV",
    "link": "/shop/AGV"
  },
  {
    "id": 2,
    "category": "board",
    "label": "Board",
    "link": "/shop/board"
  },
  {
    "id": 3,
    "category": "SENSOR",
    "label": "Sensor",
    "link": "/shop/SENSOR"
  },
  {
    "id": 4,
    "category": "IOT",
    "label": "IoT",
    "link": "/shop/IOT"
  },
  {
    "id": 152,
    "category": "EVENT",
    "label": "Event",
    "link": "/EVENT"
  }
];

export const CATEGORY = [
  {
    "id": 1,
    "category": "AGV",
  },
  {
    "id": 2,
    "category": "board",
  },
  {
    "id": 3,
    "category": "SENSOR",
  },
  {
    "id": 4,
    "category": "IOT",
  },
];

export const EDITPRODUCT_TABLE_COLUMNS = [
  {
    label: 'No.',
    value: 'id',
  },
  {
    label: '제품명',
    value: 'pName',
  },
  {
    label: '카테고리',
    value: 'category',
  },
  {
    label: '세부 카테고리',
    value: 'subcategory',
  },
  {
    label: '재고',
    value: 'moq',
  },
  {
    label: '가격',
    value: 'pCost',
  },
  {
    label: '인치',
    value: 'inch',
  },
  {
    label: '소재',
    value: 'material',
  },
  {
    label: '회사',
    value: 'brand',
  },
  {
    label: '색상',
    value: 'color',
  },
  {
    label: 'm.o.q',
    value: 'moq',
  },
  {
    label: '준비기간',
    value: 'prepare',
  },
  {
    label: '할인율',
    value: 'dcrate',
  },
  {
    label: 'operation',
    value: 'operation',
  },
];