import React from 'react';
import { Tabs } from 'antd';
import { useState, useEffect } from 'react';
import ProductCarousel from '../../Main/ProductCarousel';
import Mainslide from '../../Main/Mainslide';
import { fetchData } from '../../../service/instance';
import NzrProductCarousel from '../../Main/NzrProductCarousel';
import { getProductData } from '../../../utils/function';

function MainControl() {
  const [items, setItems] = useState();

  async function getNzrSignature() {
    const response = await fetchData('/api/nzr-signature');
    if (response.status === 200) {
      const { products, mainControl } = response.data;
      return { products, mainControl };
    }
  }

  useEffect(() => {
    async function init() {
      const newProducts = await getProductData('latest');
      const bestPoructs = await getProductData('rateDesc');
      const { products: nzrProducts, mainControl } = await getNzrSignature();

      const newItems = [
        {
          key: 'main-product-edit-tab-1',
          label: 'NZR Signature',
          children: (
            <NzrProductCarousel data={nzrProducts} mainControl={mainControl} />
          ),
        },
        {
          key: 'main-product-edit-tab-2',
          label: 'Best parts',
          children: <ProductCarousel data={bestPoructs} />,
        },
        {
          key: 'main-product-edit-tab-3',
          label: 'New parts',
          children: <ProductCarousel data={newProducts} />,
        },
      ];
      setItems(newItems);
    }
    init();
  }, []);
  return (
    <div className='main ptx'>
      <div className='mainslide'>
        <Mainslide />
      </div>
      <div className='w-full maintabs displaybox'>
        <Tabs defaultActiveKey='1' items={items} />
      </div>
    </div>
  );
}

export default MainControl;
