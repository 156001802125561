import { fetchData } from "../service/instance";
import { CATEGORY } from "./constant";


export async function getIotProductData(sorter) {
  const params = {
    page: 1,
    size: 10,
    sorter: sorter,
    cate: 'IOT'
  };
  const response = await fetchData('/api/products', params);
  if (response.status === 200) {
    const { data } = response.data;
    return data;
  }
}

export async function getProductData(sorter) {
  const params = {
    page: 1,
    size: 10,
    sorter: sorter,
  };
  const response = await fetchData('/api/products', params);
  if (response.status === 200) {
    const { data } = response.data;
    return data;
  }
}

export function truncateString(str, n) {
  if (str.length > n) {
    return str.slice(0, n) + '...';
  }
  return str;
}

export const getCategoryId = (category) => {
  const targetCategory = CATEGORY.find(item => item.category === category);
  const categoryId = targetCategory?.id;
  return categoryId;
}

export const groupByCategory = (data) => {
  const result = {};

  data.forEach((item) => {
    const category = item.category; // 카테고리
    const categoryId = item.categoryId; // 카테고리 번호
    const subcategoryId = item.id; // 서브카테고리 번호
    const subcategory = {
      categoryId: categoryId,
      id: subcategoryId,
      subcategory: item.subcategory,
    }

    // 카테고리가 존재하지 않으면 초기화
    if (!result[category]) {
      result[category] = [];
    }

    // categoryId이 같은 경우에만 묶기
    if (!result[category][categoryId]) {
      result[category][categoryId] = [];
    }

    result[category][categoryId].push(subcategory);
  });

  // categoryId을 키로 하는 객체로 변환
  const finalResult = {};
  for (const category in result) {
    finalResult[category] = result[category].flat(); // 2차원 배열을 1차원으로 변환
  }

  return finalResult;
}