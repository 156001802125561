import Slider from 'react-slick';
import { truncateString } from '../../utils/function';
import { useNavigate } from 'react-router-dom';

function ProductCarousel({ data }) {
  console.log(data);

  const navigate = useNavigate();
  const navigateProductDetail = (product) => {
    const category = product.category;
    const subCategory = product.subcategory;
    const productId = product.id;
    navigate(`/shop/${category}/${subCategory}/${productId}`);
  };

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <div className='w-full'>
      <Slider {...settings}>
        {/* <ul className='w-full'> */}
        {data &&
          data.map((product) => (
            <div className='w-full border border-solid border-[#4b6cb7] p-[6px]'>
              <div>
                <span>{product.brand}</span>
                <span>[상품코드: {product.id}]</span>
              </div>
              <div
                className='w-full h-[200px] cursor-pointer'
                onDoubleClick={() => navigateProductDetail(product)}
              >
                <img
                  src={product.img1}
                  alt='product 이미지'
                  className='w-full h-full'
                  width={200}
                  height={150}
                />
              </div>
              <div>{truncateString(product.pName, 19)}</div>
              <div className='min-h-[20px]'>
                <span>{product.inch}</span>
                <span>{product.material}</span>
                <span>{product.color}</span>
              </div>
              <div className='min-h-[20px]'>
                {truncateString(product.pDetail, 10)}
              </div>
              <div className='flex justify-between'>
                <div className='flex gap-2'>
                  <div className='line-through'>{product.pCost}</div>
                  <div className='bg-[#0b255b] text-white'>
                    {product.dcrate}%
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div className='bg-[#0b255b] text-white'>m.o.q</div>
                  <div>{product.moq}</div>
                </div>
              </div>
              <div className='flex justify-between'>
                <div className='text-[#0b255b] font-bold text-xl'>
                  {product.pPrice}원
                </div>
                <div className='flex gap-2'>
                  <div>준비</div>
                  <div>약 {product.prepare}일</div>
                </div>
              </div>
            </div>
          ))}
        {/* </ul> */}
      </Slider>
    </div>
  );
}

export default ProductCarousel;
