import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import CategoryManage from './category/CategoryManage';
import Maincontrol from './mainSetting/Maincontrol';
import Deliverwrite from './uploadProduct/Deliverwrite';
import UploadProduct from './uploadProduct/UploadProduct';
import EditProduct from './editProduct/EditProduct';
import Userlist from './user/Userlist';
import UploadEvent from './event/UploadEvent';
import EditEvent from './editEvent/EditEvent';
function Admin({ setBack }) {
  useEffect(() => {
    setBack(true);
  }, []);

  const items = [
    {
      key: '0',
      label: `운송장 입력`,
      children: <Deliverwrite />,
    },
    {
      key: '1',
      label: `제품 등록`,
      children: <UploadProduct />,
    },
    {
      key: '2',
      label: `제품 수정`,
      children: <EditProduct />,
    },
    {
      key: '3',
      label: `이벤트 등록`,
      children: <UploadEvent />,
    },
    {
      key: '4',
      label: `이벤트 수정`,
      children: <EditEvent />,
    },
    {
      key: '5',
      label: `메인 설정`,
      children: <Maincontrol />,
    },
    {
      key: '6',
      label: `카테고리 관리`,
      children: <CategoryManage />,
    },
    {
      key: '7',
      label: `유저 관리`,
      children: <Userlist />,
    },
  ];
  return (
    <div className='Admin main displaybox'>
      <Tabs defaultActiveKey='0' items={items} />
    </div>
  );
}

export default Admin;
