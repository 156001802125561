import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faqOption, noticeOption, qnaOption } from '../../../../service/options';
import { Wfaq, Wnotice, Wqna, Wqnaanswer } from '../../../../service/product';
import { useAuthStore } from '../../../../store/useAuthStore';
import ReactQuills from '../../../admin/uploadProduct/ReactQuills';
function Writenotice({ setBack }) {
    const { userInfo } = useAuthStore();
    const userId = userInfo.userId;
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pathnum2 = location.pathname.split('/')[2].split('&')[0];
    const num = searchParams.get('productid');
    const [values, setValues] = useState({
        title: '',
        category: '',
    });

    useEffect(() => {
        setBack(true);
    }, []);
    const [value, setValue] = useState('');
    const handleChange = (id, value) => {
        setValues((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        if (id == 'title') {
            setValues((prevState) => ({
                ...prevState,
                [id]: value.target.value,
            }));
        }
    };
    function addProduct() {
        {
            pathnum2 === 'notice'
                ? Wnotice(values.title, value, userId, values.category)
                      .then((response) => {
                          navigate('/customer-service/notice');
                      })
                      .catch((error) => {
                          console.error(error);
                      })
                : pathnum2 === 'faq'
                ? Wfaq(values.title, value, userId, values.category)
                      .then((response) => {
                          navigate('/customer-service/FAQ');
                      })
                      .catch((error) => {
                          console.error(error);
                      })
                : pathnum2 === 'qna'
                ? Wqna(values.title, value, userId, values.category)
                      .then((response) => {
                          navigate(`/center/qna`);
                      })
                      .catch((error) => {
                          console.error(error);
                      })
                : Wqnaanswer(value, values.writer, num)
                      .then((response) => {
                          navigate(`/center/qna`);
                      })
                      .catch((error) => {
                          console.error(error);
                      });
        }
    }

    return (
        <div className="UploadProduct main displaybox">
            {pathnum2 !== 'qnaanswer' ? (
                <div className="writeT" style={{ display: 'flex', alignContent: 'center' }}>
                    <Select
                        style={{ width: '100px' }}
                        options={
                            pathnum2 === 'notice'
                                ? noticeOption()[0]
                                : pathnum2 === 'faq'
                                ? faqOption()[0]
                                : qnaOption()[0]
                        }
                        onChange={(value) => handleChange('category', value)}
                    ></Select>
                    <Input
                        className="productSelect con"
                        placeholder="게시물제목"
                        onChange={(value) => handleChange('title', value)}
                    />
                </div>
            ) : (
                ''
            )}
            <ReactQuills value={value} setValue={setValue} />
            <Button className="submitbtns" onClick={addProduct}>
                전송
            </Button>
        </div>
    );
}

export default Writenotice;
