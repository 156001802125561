// import React, { useState } from 'react';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Footer from './Footer';
// import Header from './Header';
// import MHeaer from './MHeaer';
// import { PC, Tablet } from './MediaQuery';
// import Event from './components/Event/Event';
// import Eventpg from './components/Event/Eventpg';
// import Iot from './components/Iot/Iot';
// import Main from './components/Main/Main';
// import Admin from './components/admin/Admin';
// import Needs from './components/needs/Needs';
// import Center from './components/other/Center/Center';
// import Itemcon from './components/other/Center/Itemcon';
// import Updatecon from './components/other/Center/Write/Updatecon';
// import Writenotice from './components/other/Center/Write/Writenotice';
// import Sub from './components/other/Sub/Sub';
// import Buy from './components/shop/buy/Buy';
// import BuyaddAddress from './components/shop/buy/BuyaddAddress';
// import Buyaddressplus from './components/shop/buy/Buyaddressplus';
// import Productdetail from './components/shop/productpg/Productdetail';
// import Shop from './components/shop/productpg/Shop';
// import Cart from './components/user/Cart';
// import Login from './components/user/Login';
// import Myinfochage from './components/user/Myinfochage';
// import Mypage from './components/user/Mypage';
// import Signup from './components/user/Signup';
// import { useAuthStore } from './store/useAuthStore';

// const Approuter = () => {
//   const [back, setBack] = useState(true);
//   // const [filter, setFilter] = useState('');
//   const [sb, setSb] = useState(false);
//   const [show, setShow] = useState(true);
//   const { isLogin } = useAuthStore();
//   if (isLogin) {
//     return (
//       <div className='contain'>
//         <BrowserRouter>
//           {show ? (
//             <>
//               <PC>
//                 <Header setSb={setSb} sb={sb} />
//               </PC>
//               <Tablet>
//                 <MHeaer back={back} setSb={setSb} sb={sb} />
//               </Tablet>
//             </>
//           ) : (
//             ''
//           )}

//           <Routes>
//             <Route path='/' element={<Main setBack={setBack} />} />
//             <Route path='/Admin' element={<Admin setBack={setBack} />} />
//             {/* <Route path="/UploadProduct" element={<UploadProduct/> }/> */}
//             <Route path='/SearchResult' element={<div>보류</div>} />
//             <Route path='/Mypage' element={<Mypage setBack={setBack} />} />
//             <Route path='/Myinfo' element={<Myinfochage setBack={setBack} />} />
//             <Route path='/Cart' element={<Cart setBack={setBack} />} />
//             <Route path='/shop/:cate' element={<Shop setBack={setBack} />} />
//             <Route
//               path='/shop/:cate/:subcate'
//               element={<Shop setBack={setBack} />}
//             />
//             <Route
//               path='/shop/:cate/:subcate/:id'
//               element={<Productdetail setBack={setBack} />}
//             />
//             {/* <Route path="/IOT" element={<Iot setBack={setBack} />} />
//                         <Route path="/IOT/:id" element={<Iot setBack={setBack} />} /> */}
//             <Route path='/Sub/:id' element={<Sub setBack={setBack} />} />
//             <Route path='/Sub' element={<Sub setBack={setBack} />} />

//             <Route path='/buy' element={<Buy setBack={setBack} />} />
//             <Route
//               path='/customer-service'
//               element={<Center setBack={setBack} />}
//             />
//             <Route
//               path='/customer-service/:id'
//               element={<Center setBack={setBack} />}
//             />
//             <Route
//               path='/Write/:id'
//               element={<Writenotice setBack={setBack} />}
//             />
//             <Route
//               path='/Update/:id'
//               element={<Updatecon setBack={setBack} />}
//             />
//             <Route
//               path='/customer-service/:qna/:noticecon'
//               element={<Itemcon setBack={setBack} />}
//             />

//             <Route path='/Event' element={<Event setBack={setBack} />} />
//             <Route path='/Event/:id' element={<Eventpg setBack={setBack} />} />
//             <Route
//               path='/address'
//               element={<Buyaddressplus setShow={setShow} />}
//             />
//             <Route
//               path='/addaddress'
//               element={<BuyaddAddress setShow={setShow} />}
//             />

//             <Route
//               path='/Login'
//               element={<Login setBack={setBack} setShow={setShow} />}
//             />
//             <Route
//               path='/needs'
//               element={<Needs setShow={setShow} setBack={setBack} />}
//             />
//           </Routes>
//           {show ? <Footer /> : ''}
//         </BrowserRouter>
//       </div>
//     );
//   } else {
//     return (
//       <div className='contain'>
//         <BrowserRouter>
//           {show ? (
//             <>
//               <PC>
//                 <Header setSb={setSb} sb={sb} />
//               </PC>
//               <Tablet>
//                 <MHeaer back={back} setSb={setSb} sb={sb} />
//               </Tablet>
//             </>
//           ) : (
//             ''
//           )}
//           <Routes>
//             <Route path='/' element={<Main setBack={setBack} />} />
//             <Route path='/:cate' element={<Shop setBack={setBack} />} />
//             <Route path='/buy' element={<Buy setBack={setBack} />} />
//             <Route
//               path='/:cate/:subcate'
//               element={<Shop setBack={setBack} />}
//             />
//             <Route
//               path='/:cate/:subcate/:id'
//               element={<Productdetail setBack={setBack} />}
//             />
//             <Route
//               path='/Signup'
//               element={
//                 <Signup setBack={setBack} show={show} setShow={setShow} />
//               }
//             />
//             <Route
//               path='/Login'
//               element={<Login setBack={setBack} setShow={setShow} />}
//             />
//             <Route path='/Sub/:id' element={<Sub setBack={setBack} />} />
//             <Route path='/Sub' element={<Sub setBack={setBack} />} />
//             <Route path='/event' element={<Event setBack={setBack} />} />
//             <Route path='/event/:id' element={<Eventpg setBack={setBack} />} />
//             <Route
//               path='/customer-service'
//               element={<Center setBack={setBack} />}
//             />
//             <Route
//               path='/IOT'
//               element={<Iot back={true} setBack={setBack} />}
//             />

//             <Route
//               path='/customer-service/:id'
//               element={<Center setBack={setBack} />}
//             />
//             <Route
//               path='/customer-service/:qna/:noticecon'
//               element={<Itemcon setBack={setBack} />}
//             />
//             <Route
//               path='/needs'
//               element={<Needs setShow={setShow} setBack={setBack} />}
//             />
//             <Route path='/Cart' element={<Cart setBack={setBack} />} />
//           </Routes>
//           {show ? <Footer /> : ''}
//         </BrowserRouter>
//       </div>
//     );
//   }
// };

// export default Approuter;

import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import MHeaer from './MHeaer';
import { PC, Tablet } from './MediaQuery';
import Event from './components/Event/Event';
import Eventpg from './components/Event/Eventpg';
import Iot from './components/Iot/Iot';
import Main from './components/Main/Main';
import Admin from './components/admin/Admin';
import Needs from './components/needs/Needs';
import Center from './components/other/Center/Center';
import Itemcon from './components/other/Center/Itemcon';
import Updatecon from './components/other/Center/Write/Updatecon';
import Writenotice from './components/other/Center/Write/Writenotice';
import Sub from './components/other/Sub/Sub';
import Buy from './components/shop/buy/Buy';
import BuyaddAddress from './components/shop/buy/BuyaddAddress';
import Buyaddressplus from './components/shop/buy/Buyaddressplus';
import Productdetail from './components/shop/productpg/Productdetail';
import Shop from './components/shop/productpg/Shop';
import Cart from './components/user/Cart';
import Login from './components/user/Login';
import Myinfochage from './components/user/Myinfochage';
import Mypage from './components/user/Mypage';
import Signup from './components/user/Signup';
import { useAuthStore } from './store/useAuthStore';

// BrowserRouter 내부에서 현재 경로에 따라 헤더/푸터 렌더링 여부를 결정하는 컴포넌트
const AppRoutes = ({ back, setBack, sb, setSb, isLogin }) => {
  const location = useLocation();
  // 로그인 페이지일 때만 헤더와 푸터를 숨깁니다.
  const hideHeader = location.pathname === '/Login';

  return (
    <>
      {!hideHeader && (
        <>
          <PC>
            <Header setSb={setSb} sb={sb} />
          </PC>
          <Tablet>
            <MHeaer back={back} setSb={setSb} sb={sb} />
          </Tablet>
        </>
      )}

      <Routes>
        {isLogin ? (
          <>
            <Route path='/' element={<Main setBack={setBack} />} />
            <Route path='/Admin' element={<Admin setBack={setBack} />} />
            <Route path='/SearchResult' element={<div>보류</div>} />
            <Route path='/Mypage' element={<Mypage setBack={setBack} />} />
            <Route path='/Myinfo' element={<Myinfochage setBack={setBack} />} />
            <Route path='/Cart' element={<Cart setBack={setBack} />} />
            <Route path='/shop/:cate' element={<Shop setBack={setBack} />} />
            <Route
              path='/shop/:cate/:subcate'
              element={<Shop setBack={setBack} />}
            />
            <Route
              path='/shop/:cate/:subcate/:id'
              element={<Productdetail setBack={setBack} />}
            />
            <Route path='/Sub/:id' element={<Sub setBack={setBack} />} />
            <Route path='/Sub' element={<Sub setBack={setBack} />} />
            <Route path='/buy' element={<Buy setBack={setBack} />} />
            <Route
              path='/customer-service'
              element={<Center setBack={setBack} />}
            />
            <Route
              path='/customer-service/:id'
              element={<Center setBack={setBack} />}
            />
            <Route
              path='/Write/:id'
              element={<Writenotice setBack={setBack} />}
            />
            <Route
              path='/Update/:id'
              element={<Updatecon setBack={setBack} />}
            />
            <Route
              path='/customer-service/:qna/:noticecon'
              element={<Itemcon setBack={setBack} />}
            />
            <Route path='/Event' element={<Event setBack={setBack} />} />
            <Route path='/Event/:id' element={<Eventpg setBack={setBack} />} />
            <Route path='/address' element={<Buyaddressplus />} />
            <Route path='/addaddress' element={<BuyaddAddress />} />
            <Route
              path='/Login'
              element={<Login setBack={setBack} setShow={() => {}} />}
            />
            <Route path='/needs' element={<Needs setBack={setBack} />} />
          </>
        ) : (
          <>
            <Route path='/' element={<Main setBack={setBack} />} />
            <Route path='/:cate' element={<Shop setBack={setBack} />} />
            <Route path='/buy' element={<Buy setBack={setBack} />} />
            <Route
              path='/:cate/:subcate'
              element={<Shop setBack={setBack} />}
            />
            <Route
              path='/:cate/:subcate/:id'
              element={<Productdetail setBack={setBack} />}
            />
            <Route path='/Signup' element={<Signup setBack={setBack} />} />
            <Route
              path='/Login'
              element={<Login setBack={setBack} setShow={() => {}} />}
            />
            <Route path='/Sub/:id' element={<Sub setBack={setBack} />} />
            <Route path='/Sub' element={<Sub setBack={setBack} />} />
            <Route path='/event' element={<Event setBack={setBack} />} />
            <Route path='/event/:id' element={<Eventpg setBack={setBack} />} />
            <Route
              path='/customer-service'
              element={<Center setBack={setBack} />}
            />
            <Route
              path='/IOT'
              element={<Iot back={true} setBack={setBack} />}
            />
            <Route
              path='/customer-service/:id'
              element={<Center setBack={setBack} />}
            />
            <Route
              path='/customer-service/:qna/:noticecon'
              element={<Itemcon setBack={setBack} />}
            />
            <Route path='/needs' element={<Needs setBack={setBack} />} />
            <Route path='/Cart' element={<Cart setBack={setBack} />} />
          </>
        )}
      </Routes>
      {!hideHeader && <Footer />}
    </>
  );
};

const Approuter = () => {
  const [back, setBack] = useState(true);
  const [sb, setSb] = useState(false);
  const { isLogin } = useAuthStore();

  return (
    <div className='contain'>
      <BrowserRouter>
        <AppRoutes
          back={back}
          setBack={setBack}
          sb={sb}
          setSb={setSb}
          isLogin={isLogin}
        />
      </BrowserRouter>
    </div>
  );
};

export default Approuter;
