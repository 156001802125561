import { Button } from 'antd';
import { addNeeds } from '../../service/function';
import React from 'react';
import { useAuthStore } from '../../store/useAuthStore';
function Needsbtn(props) {
  const userInfo = useAuthStore((state) => state.userInfo);
  const userId = userInfo.userId;
  const onClick = () => {
    addNeeds(props.productid, userId, props.counter).then(function (response) {
      if (response.data == true) alert('추가되었습니다');
    });
  };

  return (
    <Button onClick={onClick} className='neeedsbtn  btnstyle'>
      ♡
    </Button>
  );
}

export default Needsbtn;
