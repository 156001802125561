import React from 'react';
import { Tabs } from 'antd';
import { useState, useEffect } from 'react';
import ProductCarousel from './ProductCarousel';
import Mainslide from './Mainslide';
import { fetchData } from '../../service/instance';
import Mainiot from './Mainiot';
import Mainevent from './Mainevent';
import Mainnotice from './Mainnotice';

function Main({ setBack }) {
  const [items, setItems] = useState();

  async function getProductData(sorter) {
    const params = {
      page: 1,
      size: 10,
      sorter: sorter,
    };
    const response = await fetchData('/api/products', params);
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    }
  }

  async function getNzrSignature() {
    const response = await fetchData('/api/nzr-signature');
    if (response.status === 200) {
      const { products } = response.data;
      return products;
    }
  }

  useEffect(() => {
    async function init() {
      const newProducts = await getProductData('latest');
      const bestPoructs = await getProductData('rateDesc');
      const nzrProducts = await getNzrSignature();

      const newItems = [
        {
          key: 'main-product-tab-1',
          label: 'Best parts',
          children: <ProductCarousel data={bestPoructs} />,
        },
        {
          key: 'main-product-tab-2',
          label: 'New parts',
          children: <ProductCarousel data={newProducts} />,
        },
        {
          key: 'main-product-tab-3',
          label: 'NZR Signature',
          children: <ProductCarousel data={nzrProducts} />,
        },
      ];
      setItems(newItems);
    }
    init();
    setBack && setBack(false);
  }, []);
  return (
    <div className='main ptx'>
      <div className='mainslide'>
        <Mainslide />
      </div>
      <div className='w-full maintabs displaybox'>
        <Tabs defaultActiveKey='1' items={items} />
      </div>

      <div className='mainbgwrap'>
        <div className='mainbg'></div>
        <div className='displaybox'>
          <Mainiot />
        </div>
      </div>
      <div className='mainbgwrap'>
        <div className='displaybox'>
          <Mainevent />
        </div>
      </div>
      <div className='mainbgwrap'>
        <div className='displaybox'>
          <Mainnotice />
        </div>
      </div>
    </div>
  );
}

export default Main;
