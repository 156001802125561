import { useNavigate } from 'react-router-dom';
import { truncateString } from '../../../utils/function';
import { useState } from 'react';
import { addCart } from '../../../service/product'; // 실제 장바구니 담기 함수

// FlatComponent: 목록형 뷰 (기존 디자인 그대로)
function FlatComponent({ products, handle }) {
  // 각 제품의 수량을 관리 (키: product.id, 값: 수량)
  const [quantities, setQuantities] = useState({});

  // 제품의 현재 수량 반환 (기본값 1)
  const getQuantity = (id) => quantities[id] || 1;

  // 수량 증가 함수
  const increment = (id) => {
    setQuantities((prev) => ({ ...prev, [id]: getQuantity(id) + 1 }));
  };

  // 수량 감소 함수 (최소 1)
  const decrement = (id) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: getQuantity(id) > 1 ? getQuantity(id) - 1 : 1,
    }));
  };

  // 장바구니 담기 함수: 현재 수량과 함께 API 호출
  const handleAddToCart = (id) => {
    const qty = getQuantity(id);
    addCart(id, null, qty) // userId는 내부 처리 또는 별도 관리
      .then(() => alert('카트에 추가되었습니다'))
      .catch((error) => console.error(error));
  };

  return (
    <ul className='w-full'>
      {products &&
        products.map((product) => (
          <li
            key={product.id}
            className='w-full flex gap-4 border-t border-solid border-[#4b6cb7] p-[6px]'
          >
            {/* 이미지 영역 */}
            <div className='w-[100px] h-full'>
              <img
                src={product.img1}
                alt='product 이미지'
                className='w-full h-full cursor-pointer'
                onClick={() => handle(product)}
              />
            </div>
            {/* 제품 정보 영역 */}
            <div className='flex flex-col justify-between flex-1 py-[10px]'>
              <div className='flex gap-2'>
                <div>{product.brand}</div>
                <div>{truncateString(product.pName, 10)}</div>
                <div>[상품코드: {product.id}]</div>
              </div>
              <div className='flex gap-2'>
                <div>{product.inch} inch</div>
                <div>{product.material}</div>
                <div>{product.color}</div>
                <div>{truncateString(product.pDetail, 10)}</div>
              </div>
            </div>
            {/* 가격 및 기타 정보 영역 */}
            <div className='flex flex-col justify-between py-[10px]'>
              <div className='flex gap-2'>
                <div className='line-through'>{product.pCost}</div>
                <div className='bg-[#0b255b] text-white'>{product.dcrate}%</div>
                <div className='flex gap-1'>
                  <div className='bg-[#0b255b] text-white rounded-md'>
                    m.o.q
                  </div>
                  <div>{product.moq}</div>
                </div>
              </div>
              <div className='flex gap-2'>
                <div className='text-[#0b255b] font-bold text-xl'>
                  {product.pPrice}원
                </div>
                <div className='flex gap-1'>
                  <div>준비</div>
                  <div>약 {product.prepare}일</div>
                </div>
              </div>
            </div>
            {/* 수량 조절 영역 (기존 디자인 유지) */}
            <div className='flex items-center'>
              <button
                onClick={() => increment(product.id)}
                className='px-2 py-1'
              >
                +
              </button>
              <div className='px-2'>{getQuantity(product.id)}</div>
              <button
                onClick={() => decrement(product.id)}
                className='px-2 py-1'
              >
                -
              </button>
            </div>
            {/* 좋아요 및 장바구니 버튼 영역 (원래 UI 그대로) */}
            <div className='flex flex-col justify-center'>
              <div>
                <button>🤍</button>
              </div>
              <div>
                <button onClick={() => handleAddToCart(product.id)}>🛒</button>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
}

// GridComponent: 격자형 뷰 (기존 디자인 그대로)
// 수량 및 장바구니 기능을 추가했으며, 기존 그리드 디자인 클래스 유지
function GridComponent({ products, gridTemplate, handle }) {
  // 각 제품의 수량을 관리 (키: product.id, 값: 수량)
  const [quantities, setQuantities] = useState({});

  // 제품의 현재 수량 반환 (기본값 1)
  const getQuantity = (id) => quantities[id] || 1;

  // 수량 증가 함수
  const increment = (id) => {
    setQuantities((prev) => ({ ...prev, [id]: getQuantity(id) + 1 }));
  };

  // 수량 감소 함수 (최소 1)
  const decrement = (id) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: getQuantity(id) > 1 ? getQuantity(id) - 1 : 1,
    }));
  };

  // 장바구니 담기 함수: 현재 수량과 함께 API 호출
  const handleAddToCart = (id) => {
    const qty = getQuantity(id);
    addCart(id, null, qty)
      .then(() => alert('카트에 추가되었습니다'))
      .catch((error) => console.error(error));
  };

  // 그리드 레이아웃 관련 클래스 함수들 (기존 그대로)
  const gridWrap = () => {
    if (gridTemplate === 'default') {
      return 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5';
    } else if (gridTemplate === 'grid2') {
      return 'grid grid-cols-2';
    }
  };

  const gridImgHeight = () => {
    if (gridTemplate === 'default') {
      return 'h-[200px]';
    } else if (gridTemplate === 'grid2') {
      return 'h-[400px]';
    }
  };

  const gridHidden = () => {
    if (gridTemplate === 'default') {
      return 'block';
    } else if (gridTemplate === 'grid2') {
      return 'block';
    }
  };

  return (
    <ul className={`${gridWrap()} gap-2`}>
      {products &&
        products.map((product) => (
          <li
            key={product.id}
            className='w-full border border-solid border-[#4b6cb7] p-[6px]'
          >
            <div>
              <span>{product.brand}</span>
              <span>[상품코드: {product.id}]</span>
            </div>
            <div
              className={`w-full ${gridImgHeight()} cursor-pointer`}
              onClick={() => handle(product)}
            >
              <img
                src={product.img1}
                alt='product 이미지'
                className='w-full h-full'
              />
            </div>
            <div>{truncateString(product.pName, 19)}</div>
            <div className={`min-h-[20px] ${gridHidden()}`}>
              <span>{product.inch} inch&nbsp; </span>
              <span>{product.material}&nbsp; </span>
              <span>{product.color}</span>
            </div>
            <div className={`min-h-[20px] ${gridHidden()}`}>
              {truncateString(product.pDetail, 19)}
            </div>
            <div className='flex justify-between'>
              <div className='flex gap-2'>
                <div className='line-through'>{product.pCost}</div>
                <div className='bg-[#0b255b] text-white'>{product.dcrate}%</div>
              </div>
              <div className='flex gap-2'>
                <div className='bg-[#0b255b] text-white'>m.o.q</div>
                <div>{product.moq}</div>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='text-[#0b255b] font-bold text-xl'>
                {product.pPrice}원
              </div>
              <div className='flex gap-2'>
                <div>준비</div>
                <div>약 {product.prepare}일</div>
              </div>
            </div>
            {/* 수량 조절 영역 (기존 그리드 디자인 유지) */}
            <div className='flex justify-between'>
              <div className={`flex ${gridHidden()}`}>
                <button
                  onClick={() => increment(product.id)}
                  className='w-[40px] h-[30px] text-center align-middle border border-solid border-blue-500'
                >
                  +
                </button>
                <div className='w-[40px] text-center align-middle border border-solid border-blue-500'>
                  {getQuantity(product.id)}
                </div>
                <button
                  onClick={() => decrement(product.id)}
                  className='w-[40px] text-center align-middle border border-solid border-blue-500'
                >
                  -
                </button>
              </div>
              <div className='flex'>
                <div
                  className={`w-[50px] text-center align-middle border border-solid border-blue-500 ${gridHidden()}`}
                >
                  🤍
                </div>
                <div
                  onClick={() => handleAddToCart(product.id)}
                  className={`w-[50px] text-center align-middle border border-solid border-blue-500 ${gridHidden()}`}
                  style={{ cursor: 'pointer' }}
                >
                  🛒
                </div>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
}

// ProductList: gridTemplate 값에 따라 GridComponent 또는 FlatComponent 렌더링
function ProductList({ products, gridTemplate }) {
  const navigate = useNavigate();
  // 제품 상세 페이지로 이동
  const navigateProductDetail = (product) => {
    const category = product.category;
    const subCategory = product.subcategory;
    const productId = product.id;
    navigate(`/shop/${category}/${subCategory}/${productId}`);
  };

  return (
    <>
      {gridTemplate !== 'flat' ? (
        <GridComponent
          products={products}
          gridTemplate={gridTemplate}
          handle={navigateProductDetail}
        />
      ) : (
        <FlatComponent products={products} handle={navigateProductDetail} />
      )}
    </>
  );
}

export default ProductList;
