import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

const Crumb1 = ({ num }) => {
  return (
    <Breadcrumb
      className='breacrumb'
      separator='>'
      items={[
        {
          title: 'Home',
          href: '/',
        },
        {
          title: <Link to={`/shop/${num}`}>{num}</Link>,
        },
      ]}
    />
  );
};

const Crumb2 = ({ num1, num2 }) => {
  return (
    <Breadcrumb
      className='breacrumb'
      separator='>'
      items={[
        {
          title: 'Home',
          href: '/',
        },
        {
          title: <Link to={`/shop/${num1}`}>{num1}</Link>,
        },
        {
          title: <Link to={`/shop/${num1}/${num2}`}>{num2}</Link>,
        },
      ]}
    />
  );
};

const Crumb3 = ({ num1, num2, num3, data }) => {
  return (
    <Breadcrumb
      className='breacrumb'
      separator='>'
      items={[
        {
          title: 'Home',
          href: '/',
        },
        {
          title: <Link to={`/shop/${num1}`}>{num1}</Link>,
        },
        {
          title: <Link to={`/shop/${num1}/${num2}`}>{num2}</Link>,
        },
        {
          title: <Link to={`/shop/${num1}/${num2}/${num3}`}>{num2}</Link>,
        },
      ]}
    />
  );
};

export { Crumb1, Crumb2, Crumb3 };
