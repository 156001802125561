import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    return (
        <PopupContext.Provider value={{ showPopup, setShowPopup, dontShowAgain, setDontShowAgain }}>
            {children}
        </PopupContext.Provider>
    );
};
PopupProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const usePopup = () => useContext(PopupContext);
