import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { fetchDataPost } from '../../../service/instance';
import Productinfo from './Productinfo';
import ReactQuills from './ReactQuills';
import UploadThumb from './UploadThumb';

function UploadProduct() {
  const [fileList, setFileList] = useState([]);
  const [productinfo, setProductInfo] = useState({
    categoryId: null,
    subcategoryId: null,
    pName: '',
    pquantity: '',
    pCost: '',
    dcrate: '',
    moq: '',
    prepare: '',
    inch: '',
    color: null,
    material: null,
    brand: '',
    pDetail: '',
  });
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('');

  // const meta = {
  //     title: 'title 1',
  //     contents: 'contents 1',
  // };

  const resetProductInfo = () => {
    setProductInfo({
      categoryId: null,
      subcategoryId: null,
      pName: '',
      pquantity: '',
      pCost: '',
      dcrate: '',
      moq: '',
      prepare: '',
      inch: '',
      color: null,
      material: null,
      brand: '',
      pDetail: '',
    });
    setValue('');
    setTitle('');
  };

  const api_uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('img', file.originFileObj);

    const response = await fetchDataPost('/api/images', formData);
    const uploadedImageUrl = response.data.url;
    return uploadedImageUrl;
    // console.log('🚀 ~ constapi_uploadImage= ~ result:', result);
  };

  const api_uploadProduct = async (imageUrls) => {
    const payload = {
      categoryId: productinfo.categoryId,
      subcategoryId: productinfo.subcategoryId,
      pName: productinfo.pName,
      pquantity: productinfo.pquantity,
      pCost: productinfo.pCost,
      dcrate: productinfo.dcrate,
      moq: productinfo.moq,
      prepare: productinfo.prepare,
      inch: productinfo.inch,
      color: productinfo.color,
      material: productinfo.material,
      brand: productinfo.brand,
      pDetail: productinfo.pDetail,
      title: title,
      content: value,
      urls: imageUrls,
    };
    const response = await fetchDataPost('/api/products', payload);

    return response;
  };

  async function addProduct() {
    if (fileList.length === 0) {
      return alert('이미지를 업로드해주세요.');
    }

    if (
      productinfo.categoryId === '' ||
      productinfo.subcategoryId === '' ||
      productinfo.pName === '' ||
      productinfo.pquantity === '' ||
      productinfo.pCost === '' ||
      productinfo.dcrate === '' ||
      productinfo.moq === '' ||
      productinfo.prepare === '' ||
      productinfo.inch === '' ||
      productinfo.color === '' ||
      productinfo.material === '' ||
      productinfo.brand === '' ||
      productinfo.pDetail === ''
    ) {
      return alert('모든 항목을 입력해주세요.');
    }

    if (title === '' || value === '') {
      return alert('게시물 제목과 내용을 입력해주세요.');
    }

    // thumbnail images upload
    const uploadImagePromises = fileList.map(async (file) => {
      return await api_uploadImage(file);
    });

    const imageUrls = await Promise.all(uploadImagePromises);
    console.log('🚀 ~ addProduct ~ result:', imageUrls);

    const res = await api_uploadProduct(imageUrls);
    if (res.status === 200) {
      alert('상품이 등록되었습니다.');
      resetProductInfo();
    }
  }

  return (
    <div className='UploadProduct'>
      <Productinfo productinfo={productinfo} setProductInfo={setProductInfo} />
      <UploadThumb fileList={fileList} setFileList={setFileList} />
      <Button className='submitbtn' onClick={addProduct}>
        전송
      </Button>
      <Input
        className='productSelect con'
        placeholder='게시물제목'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <ReactQuills value={value} setValue={setValue} />
    </div>
  );
}

export default UploadProduct;
