// import React from 'react';
// import { Checkbox, Col, Row, Button } from 'antd';
// import { useState, useEffect } from 'react';
// import { AllCartdelete, Cartdelete } from '../components_btn/Cartbtn';
// import Countbtn from '../components_btn/Countbtn';
// import { pricechange } from '../../service/function';
// import Buybtn from '../components_btn/Buybtn';
// const CheckboxGroup = Checkbox.Group;

// const Cartcheck = (props) => {
//   const [checkedList, setCheckedList] = useState([]);
//   const [indeterminate, setIndeterminate] = useState(true);
//   const [checkAll, setCheckAll] = useState(false);
//   const [total, setTotal] = useState(0);

//   const onChange = (list) => {
//     setCheckedList(list);
//     setIndeterminate(!!list.length && list.length < props.plainOptions.length);
//     setCheckAll(list.length === props.plainOptions.length);
//     updateTotal(list);
//   };

//   const onCheckAllChange = (e) => {
//     const newList = e.target.checked ? props.plainOptions : [];
//     setCheckedList(newList);
//     setIndeterminate(false);
//     setCheckAll(e.target.checked);
//     updateTotal(newList);
//   };

//   useEffect(() => {
//     updateTotal(checkedList);
//   }, [checkedList, props.cartdata]);

//   const updateTotal = (list) => {
//     let totalp = 0;
//     for (const item of props.cartdata) {
//       if (list.includes(item.productnum)) {
//         totalp += Number(item.pPrice) * item.count;
//       }
//     }
//     setTotal(totalp);
//   };

//   const CountChange = (count, ids) => {
//     const existingCount = props.cartdata.find((obj) => obj.productnum === ids);

//     if (existingCount) {
//       const newdata = props.cartdata.map((obj) =>
//         obj.productnum === ids ? { ...obj, count: count } : obj
//       );
//       props.setCartData(newdata);
//     }
//   };
//   return (
//     <>
//       <div
//         className='checkwrap'
//         style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           marginBottom: '20px',
//           marginTop: '20px',
//         }}
//       >
//         <Checkbox
//           indeterminate={indeterminate}
//           onChange={onCheckAllChange}
//           checked={checkAll}
//         >
//           Check all
//         </Checkbox>
//         <AllCartdelete
//           setCartData={props.setCartData}
//           cartdata={props.cartdata}
//           productid={checkedList}
//           userId={props.userId}
//         />
//       </div>
//       <CheckboxGroup
//         className='check_wrap'
//         value={checkedList}
//         onChange={onChange}
//       >
//         {props.cartdata.map((value) => (
//           <div key={value.id} className='cart_check_wrap'>
//             <Checkbox value={value.productnum}></Checkbox>
//             <div className='cart_box'>
//               <div className='cart_box_img'>
//                 <img src={value.img1} />
//               </div>
//               <div className='cart_box_detail'>
//                 <div className='tbrand'>{value.brand}</div>
//                 <div className='tname'>{value.pName}</div>
//                 <div className='table4'>{pricechange(value.pPrice)}원</div>
//               </div>
//             </div>
//             <Countbtn
//               ids={value.productnum}
//               CountChange={CountChange}
//               count={value.count}
//             />
//             <Cartdelete
//               setCartData={props.setCartData}
//               cartdata={props.cartdata}
//               productid={value.productnum}
//               userId={props.userId}
//             />
//           </div>
//         ))}
//       </CheckboxGroup>
//       <div className='totalp_wrap'>
//         <div className='totalp'>총가격 : {pricechange(total)} 원</div>
//         <Buybtn
//           checkedList={checkedList}
//           userId={props.userId}
//           cartdata={props.cartdata}
//         />
//       </div>
//     </>
//   );
// };
// export default Cartcheck;

import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { AllCartdelete, Cartdelete } from '../components_btn/Cartbtn';
import Countbtn from '../components_btn/Countbtn';
import { pricechange } from '../../service/function';
import Buybtn from '../components_btn/Buybtn';
import { addCart } from '../../service/product';
const CheckboxGroup = Checkbox.Group;

const Cartcheck = (props) => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [total, setTotal] = useState(0);

  // 체크박스 변경 처리
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < props.plainOptions.length);
    setCheckAll(list.length === props.plainOptions.length);
    updateTotal(list);
  };

  // 전체 체크박스 변경 처리
  const onCheckAllChange = (e) => {
    const newList = e.target.checked ? props.plainOptions : [];
    setCheckedList(newList);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    updateTotal(newList);
  };

  // 선택된 항목에 따른 총 가격 업데이트
  useEffect(() => {
    updateTotal(checkedList);
  }, [checkedList, props.cartdata]);

  const updateTotal = (list) => {
    let totalp = 0;
    for (const item of props.cartdata) {
      if (list.includes(item.productnum)) {
        totalp += Number(item.pPrice) * item.count;
      }
    }
    setTotal(totalp);
  };

  // CountChange: Countbtn에서 호출되어 새 수량(newCount)과 해당 상품(productId)을 받음.
  // 기존 수량과의 차이(delta)를 계산한 후, 바로 addCart API를 호출하여 DB에 업데이트하고,
  // 업데이트가 성공하면 로컬 상태(props.cartdata)를 변경합니다.
  const CountChange = (newCount, productId) => {
    const product = props.cartdata.find((obj) => obj.productnum === productId);
    const prevCount = product ? product.count : 0;
    const delta = newCount - prevCount;
    if (delta !== 0) {
      // DB 업데이트: delta 값 (+1 또는 -1 등)을 전달하여 수량을 변경
      addCart(productId, props.userId, delta)
        .then(() => {
          const newdata = props.cartdata.map((obj) =>
            obj.productnum === productId ? { ...obj, count: newCount } : obj
          );
          props.setCartData(newdata);
          // console.log('수량 업데이트 완료');
        })
        .catch((err) => console.error('수량 업데이트 실패:', err));
    }
  };

  return (
    <>
      <div
        className='checkwrap'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 추가된 부분
          marginBottom: '20px',
          marginTop: '20px',
        }}
      >
        {/* <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox> */}
        <Checkbox onChange={onCheckAllChange}>전체 선택</Checkbox>
        <AllCartdelete
          setCartData={props.setCartData}
          cartdata={props.cartdata}
          productid={checkedList}
          userId={props.userId}
        />
      </div>
      <CheckboxGroup
        className='check_wrap'
        value={checkedList}
        onChange={onChange}
      >
        {props.cartdata.map((value) => (
          <div key={value.id} className='cart_check_wrap'>
            <Checkbox
              style={{ marginRight: '15px' }}
              value={value.productnum}
            />
            <div className='cart_box'>
              <div className='cart_box_img'>
                <img src={value.img1} alt='상품 이미지' />
              </div>
              <div className='cart_box_detail'>
                <div className='tbrand'>{value.brand}</div>
                <div className='tname'>{value.pName}</div>
                <div className='table4'>{pricechange(value.pPrice)}원</div>
              </div>
            </div>
            {/* Countbtn에서 수량 변경 시 CountChange(newCount, productId)를 호출 */}
            <Countbtn
              ids={value.productnum}
              CountChange={CountChange}
              count={value.count}
            />
            <Cartdelete
              setCartData={props.setCartData}
              cartdata={props.cartdata}
              productid={value.productnum}
              userId={props.userId}
            />
          </div>
        ))}
      </CheckboxGroup>
      <div className='flex justify-between mt-20' />
      <div className='totalp_wrap'>
        <div className='totalp'>총가격 : {pricechange(total)} 원</div>
        <Buybtn
          checkedList={checkedList}
          userId={props.userId}
          cartdata={props.cartdata}
        />
      </div>
    </>
  );
};

export default Cartcheck;
