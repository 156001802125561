import { useState } from 'react';
import UploadThumb from '../uploadProduct/UploadThumb';
import Fileupload from '../uploadProduct/Fileupload';
import { Button, Input, DatePicker, Select } from 'antd';
import ReactQuills from '../uploadProduct/ReactQuills';
import { fetchDataPost } from '../../../service/instance';
const { RangePicker } = DatePicker;

function UploadEvent() {
  const [fileList, setFileList] = useState([]);

  const [eventInfo, setEventInfo] = useState({
    category: null,
    title: '',
  });
  const [eventRange, setEventRange] = useState({
    startday: '',
    lastday: '',
  });

  const [value, setValue] = useState('');
  const options = [
    { label: '회원가입', value: '회원가입' },
    { label: '배송', value: '배송' },
    { label: '겔제', value: '겔제' },
    { label: '취소 및 교환 A/S' },
    { label: '대량구매', value: '대량구매' },
    { label: '증빙서류', value: '증빙서류' },
    { label: '구매', value: '구매' },
  ];

  const handleChange = (id, value) => {
    setEventInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleRangePicker = (value) => {
    setEventRange((prevState) => ({
      ...prevState,
      startday: value[0].format('YYYY-MM-DD'),
      lastday: value[1].format('YYYY-MM-DD'),
    }));
  };

  const api_uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('img', file.originFileObj);

    const response = await fetchDataPost('/api/images', formData);
    const uploadedImageUrl = response.data.url;
    return uploadedImageUrl;
    // console.log('🚀 ~ constapi_uploadImage= ~ result:', result);
  };

  const api_uploadEvent = async (thumbUrl) => {
    const payload = {
      title: eventInfo.title,
      startday: eventRange.startday,
      lastday: eventRange.lastday,
      content: value,
      thumbUrl,
      category: eventInfo.category,
    };
    return await fetchDataPost('/api/event', payload);
  };

  const addEvent = async () => {
    if (fileList.length === 0) {
      alert('썸네일을 등록해주세요');
      return;
    }
    if (fileList.length > 1) {
      alert('썸네일은 1개만 등록 가능합니다.');
      return;
    }
    if (
      !eventInfo.category ||
      !eventInfo.title ||
      !eventRange.startday ||
      !eventRange.lastday ||
      !value
    ) {
      alert('모든 항목을 입력해주세요');
      return;
    }

    const thumbUrl = await api_uploadImage(fileList[0]);
    const response = await api_uploadEvent(thumbUrl);
    console.log('🚀 ~ addEvent ~ response:', response);
    if (response.status === 200) {
      alert('이벤트 등록이 완료되었습니다.');
    }
  };

  return (
    <div className='UploadProduct'>
      <Select
        placeholder='카테고리'
        value={eventInfo.category}
        onChange={(value) => handleChange('category', value)}
        className='productSelect'
        options={options}
      />
      <RangePicker onChange={handleRangePicker} />
      <UploadThumb fileList={fileList} setFileList={setFileList} />
      <Button className='submitbtn' onClick={addEvent}>
        전송
      </Button>
      <Input
        className='productSelect con'
        placeholder='게시물제목'
        value={eventInfo.title}
        onChange={(e) => handleChange('title', e.target.value)}
      />
      <ReactQuills value={value} setValue={setValue} />
    </div>
  );
}

export default UploadEvent;
