import { Button, Input, Pagination, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { fetchData, reactQueryFnWithParams } from '../../../service/instance';
import { Crumb1, Crumb2 } from '../../components_btn/Breadcrums';
import Fixedbox from '../../components_btn/Fixedbox';
import LeftNavi from '../shop_sort/LeftNavi';
import ProductList from './ProductList';
import {
  AppstoreOutlined,
  PicLeftOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { sortList } from '../../../service/function'; // 정렬 함수 import

const { Search } = Input;

/**
 * Shop.jsx
 * 제품 목록 페이지 - 검색, 필터, 정렬, 페이지네이션, grid template 조절 등을 구현
 */
function Shop({ setBack }) {
  const location = useLocation();
  const pathnum1 = location.pathname.split('/')[2];
  const pathnum2 = location.pathname.split('/')[3];

  useEffect(() => {
    setBack(true);
  }, [pathnum1, pathnum2]);

  // 필터 관련 상태
  const [isVisableFilter, setIsVisableFilter] = useState(false);
  const toggleFilter = () => {
    setIsVisableFilter((prev) => !prev);
  };
  const inchOptions = [{ inch: '0~10' }, { inch: '11~20' }, { inch: '21~30' }];

  // brand 옵션
  const queryParamBrand = {
    path: pathnum2 ? pathnum2 : pathnum1,
    sort: 'brand',
  };
  const { data: brandOptions } = useQuery({
    queryKey: [`filterOptions-brand-${pathnum1}-${pathnum2}`],
    queryFn: () =>
      reactQueryFnWithParams(`api/product-filter`, queryParamBrand),
  });
  // material 옵션
  const queryParamMaterial = {
    path: pathnum2 ? pathnum2 : pathnum1,
    sort: 'material',
  };
  const { data: materialOptions } = useQuery({
    queryKey: [`filterOptions-material-${pathnum1}-${pathnum2}`],
    queryFn: () =>
      reactQueryFnWithParams(`api/product-filter`, queryParamMaterial),
  });
  // color 옵션
  const queryParamColor = {
    path: pathnum2 ? pathnum2 : pathnum1,
    sort: 'color',
  };
  const { data: colorOptions } = useQuery({
    queryKey: [`filterOptions-color-${pathnum1}-${pathnum2}`],
    queryFn: () =>
      reactQueryFnWithParams(`api/product-filter`, queryParamColor),
  });
  const getTargetOptions = (item) => {
    const filters = {
      inch: inchOptions,
      brand: brandOptions,
      material: materialOptions,
      color: colorOptions,
    };
    return filters[item];
  };

  // 제품 페이지네이션 및 정렬 관련 상태
  const [products, setProducts] = useState(null);
  const [page, setPage] = useState({ num: 1 });
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  // 정렬 상태 (function.js의 sortList에서 사용하는 sortType과 동일)
  // 예: 'daydesc' (최근등록순), 'sales' (판매량많은순), 'popular' (랭킹추천순), 'low' (낮은가격순), 'high' (높은가격순)
  const [sort, setSort] = useState('daydesc');

  // 제품 필터 상태
  const [activeFilters, setActiveFilters] = useState({
    inch: [],
    brand: [],
    material: [],
    color: [],
  });
  const toggleActiveFilters = (key, value) => {
    const alreadyFilter = activeFilters[key].includes(value);
    const _activeFilters = { ...activeFilters };
    if (alreadyFilter) {
      const filteredActiveFilters = _activeFilters[key].filter(
        (item) => item !== value
      );
      _activeFilters[key] = filteredActiveFilters;
      setActiveFilters(_activeFilters);
    } else {
      if (key === 'inch' && activeFilters['inch'].length > 0) {
        Swal.fire('inch는 하나의 option만 선택 가능합니다');
        return;
      }
      _activeFilters[key].push(value);
      setActiveFilters(_activeFilters);
    }
  };

  const handleFilter = () => {
    setPage({ num: 1 });
  };

  // 검색 관련 상태
  const [searchValue, setSearchValue] = useState('');
  const onChangeSearchInput = (e) => {
    setSearchValue(e.currentTarget.value);
  };
  const onClickSearch = () => {
    setPage({ num: 1 });
  };

  // grid template 상태
  const [gridTemplate, setGridTemplate] = useState('default'); // 'default', 'grid2', 'flat'

  // 제품 데이터 fetch 및 정렬 적용
  async function paginationFetch() {
    const inch = activeFilters['inch'][0]?.split('~');
    const startInch = inch?.[0];
    const endInch = inch?.[1];
    const brand = activeFilters['brand'].join(',');
    const material = activeFilters['material'].join(',');
    const color = activeFilters['color'].join(',');

    const params = {
      cate: pathnum1,
      subcategory: pathnum2,
      page: page.num,
      size,
      startInch: startInch,
      endInch: endInch,
      brand: brand,
      material: material,
      color: color,
      pName: searchValue,
    };

    const response = await fetchData('/api/products', params);
    setSearchValue(''); // 검색 인풋 초기화
    if (!response?.data?.data) return;
    const {
      data,
      page: _page,
      size: _size,
      totalElements,
      totalPages,
    } = response.data;
    setTotalElements(totalElements);
    // function.js의 sortList 함수로 정렬 적용
    const sortedProducts = sortList(data, sort);
    setProducts(sortedProducts);
  }

  useEffect(() => {
    paginationFetch();
  }, [page, size, sort]);

  useEffect(() => {
    setPage({ num: 1 });
  }, [pathnum1, pathnum2]);

  return (
    <>
      <div className='shop main displaybox'>
        <div className='shop_div'>
          <div className='shop_sort'>
            <LeftNavi />
          </div>
          <div className='shop_content'>
            <div className='flex justify-between pr-[100px]'>
              {!pathnum2 ? (
                <Crumb1 num={pathnum1} />
              ) : (
                <Crumb2 num1={pathnum1} num2={pathnum2} />
              )}
              <div className='flex items-end'>
                <Space.Compact>
                  <Search
                    placeholder='제품명을 검색해주세요.'
                    value={searchValue}
                    onChange={onChangeSearchInput}
                    onSearch={onClickSearch}
                    style={{ width: 400 }}
                  />
                </Space.Compact>
              </div>
            </div>

            <div className='w-full flex gap-4 mb-4 pr-[100px]'>
              <div>
                <Button onClick={toggleFilter}>
                  {isVisableFilter ? '필터감추기' : '필터보이기'}
                </Button>
              </div>
              <div className='flex-1'>
                <Button className='w-1/5' onClick={() => setSort('daydesc')}>
                  최근등록순
                </Button>
                <Button className='w-1/5' onClick={() => setSort('sales')}>
                  판매량많은순
                </Button>
                <Button className='w-1/5' onClick={() => setSort('popular')}>
                  랭킹추천순
                </Button>
                <Button className='w-1/5' onClick={() => setSort('low')}>
                  낮은가격순
                </Button>
                <Button className='w-1/5' onClick={() => setSort('high')}>
                  높은가격순
                </Button>
              </div>
              <div className='flex items-center'>
                <div className=''>
                  <Button onClick={() => setGridTemplate('default')}>
                    <TableOutlined />
                  </Button>
                </div>
                <div>
                  <Button onClick={() => setGridTemplate('grid2')}>
                    <AppstoreOutlined />
                  </Button>
                </div>
                <div>
                  <Button onClick={() => setGridTemplate('flat')}>
                    <PicLeftOutlined />
                  </Button>
                </div>
              </div>
            </div>

            {isVisableFilter && (
              <div className='w-full h-[200px] bg-[#c6d5fc] rounded-md flex gap-2 p-4'>
                <ul className='h-full flex gap-2'>
                  {['inch', 'brand', 'material', 'color'].map((item) => (
                    <li
                      key={item}
                      className='w-[200px] h-full bg-white rounded-md border border-solid border-[#4b6cb7]'
                    >
                      <div className='h-[40px] px-4 py-2 border-b border-solid border-[#4b6cb7] text-lg font-bold'>
                        {item}
                      </div>
                      <div
                        className='p-4'
                        style={{
                          height: 'calc(100% - 40px)',
                          overflow: 'auto',
                        }}
                      >
                        {inchOptions &&
                          brandOptions &&
                          materialOptions &&
                          colorOptions &&
                          getTargetOptions(item).map((option, index) => (
                            <div
                              key={index}
                              className='cursor-pointer'
                              style={{
                                backgroundColor: activeFilters[item].includes(
                                  Object.values(option)[0]
                                )
                                  ? '#e3e3e3'
                                  : '',
                              }}
                              onClick={() =>
                                toggleActiveFilters(
                                  item,
                                  Object.values(option)[0]
                                )
                              }
                            >
                              {Object.values(option)}
                            </div>
                          ))}
                      </div>
                    </li>
                  ))}
                </ul>
                <div className='h-full'>
                  <Button className='h-full' onClick={handleFilter}>
                    필터 적용
                  </Button>
                </div>
              </div>
            )}

            <div>
              <ProductList products={products} gridTemplate={gridTemplate} />
            </div>
            <div style={{ justifyItems: 'center' }}>
              <Pagination
                defaultCurrent={1}
                total={totalElements}
                current={page.num}
                onChange={(page) => setPage({ num: page })}
                onShowSizeChange={(page, size) => setSize(size)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;

// import { Button, Input, Pagination, Space } from 'antd';
// import React, { useEffect, useRef, useState } from 'react';
// import { useQuery } from 'react-query';
// import { useLocation } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import { fetchData, reactQueryFnWithParams } from '../../../service/instance';
// import { Crumb1, Crumb2 } from '../../components_btn/Breadcrums';
// import Fixedbox from '../../components_btn/Fixedbox';
// import LeftNavi from '../shop_sort/LeftNavi';
// import ProductList from './ProductList';
// import {
//   AppstoreOutlined,
//   PicLeftOutlined,
//   TableOutlined,
// } from '@ant-design/icons';

// const { Search } = Input;
// /**
//  * @typedef {Object} Product
//  * @property {number} id
//  * @property {string} pName
//  * @property {string} pquantity
//  * @property {string} pPrice
//  * @property {string} inch
//  * @property {string} material
//  * @property {string} brand
//  * @property {string} color
//  * @property {string} img1
//  * @property {string} img2
//  * @property {string} img3
//  * @property {string} img4
//  * @property {string} img5
//  * @property {string} updatetime
//  * @property {number} moq
//  * @property {number} prepare
//  * @property {number} dcrate
//  * @property {string} pCost
//  * @property {string} pDetail
//  * @property {string} title
//  * @property {string} content
//  * @property {number} categoryId
//  * @property {number} subcategoryId
//  * @property {string} category
//  * @property {string} subcategory
//  * @returns
//  */

// /**
//  * @template T
//  * @typedef {ReturnType<typeof import("react").useState<T>>} useState
//  */

// function Shop({ setBack }) {
//   const location = useLocation();

//   const pathnum1 = location.pathname.split('/')[2];
//   const pathnum2 = location.pathname.split('/')[3];

//   useEffect(() => {
//     setBack(true);
//   }, [pathnum1, pathnum2]);

//   // 필터
//   const [isVisableFilter, setIsVisableFilter] = useState(false);
//   const toggleFilter = () => {
//     setIsVisableFilter((prev) => !prev);
//   };

//   const inchOptions = [{ inch: '0~10' }, { inch: '11~20' }, { inch: '21~30' }];

//   // brand
//   const queryParamBrand = {
//     path: pathnum2 ? pathnum2 : pathnum1,
//     sort: 'brand',
//   };
//   const { data: brandOptions } = useQuery({
//     queryKey: [`filterOptions-brand-${pathnum1}-${pathnum2}`],
//     queryFn: () =>
//       reactQueryFnWithParams(`api/product-filter`, queryParamBrand),
//   });

//   // material
//   const queryParamMaterial = {
//     path: pathnum2 ? pathnum2 : pathnum1,
//     sort: 'material',
//   };
//   const { data: materialOptions } = useQuery({
//     queryKey: [`filterOptions-material-${pathnum1}-${pathnum2}`],
//     queryFn: () =>
//       reactQueryFnWithParams(`api/product-filter`, queryParamMaterial),
//   });

//   //color
//   const queryParamColor = {
//     path: pathnum2 ? pathnum2 : pathnum1,
//     sort: 'color',
//   };
//   const { data: colorOptions } = useQuery({
//     queryKey: [`filterOptions-color-${pathnum1}-${pathnum2}`],
//     queryFn: () =>
//       reactQueryFnWithParams(`api/product-filter`, queryParamColor),
//   });

//   const getTargetOptions = (item) => {
//     const filters = {
//       inch: inchOptions,
//       brand: brandOptions,
//       material: materialOptions,
//       color: colorOptions,
//     };
//     return filters[item];
//   };

//   // 제품 페이지네이션
//   /** @type {useState<Product[]>} */
//   const [products, setProducts] = useState(null);
//   // console.log('🚀 ~ Shop ~ products:', products);
//   const [page, setPage] = useState({ num: 1 });
//   const [totalElements, setTotalElements] = useState(0);
//   const [size, setSize] = useState(10);

//   async function paginationFetch() {
//     const inch = activeFilters['inch'][0]?.split('~');
//     const startInch = inch?.[0];
//     const endInch = inch?.[1];
//     const brand = activeFilters['brand'].join(',');
//     const material = activeFilters['material'].join(',');
//     const color = activeFilters['color'].join(',');

//     const params = {
//       cate: pathnum1,
//       subcategory: pathnum2,
//       page: page.num,
//       size,
//       startInch: startInch,
//       endInch: endInch,
//       brand: brand,
//       material: material,
//       color: color,
//       pName: searchValue,
//     };

//     const response = await fetchData('/api/products', params);
//     setSearchValue(''); // serarchInput Value 초기화
//     if (!response?.data?.data) return;
//     const {
//       data,
//       page: _page,
//       size: _size,
//       totalElements,
//       totalPages,
//     } = response.data;
//     setTotalElements(totalElements);
//     setProducts(data);
//   }

//   useEffect(() => {
//     paginationFetch();
//   }, [page, size]);

//   useEffect(() => {
//     setPage({ num: 1 });
//     // paginationFetch();
//   }, [pathnum1, pathnum2]);

//   // 제품 필터
//   const [activeFilters, setActiveFilters] = useState({
//     inch: [],
//     brand: [],
//     material: [],
//     color: [],
//   });

//   const toggleActiveFilters = (key, value) => {
//     const alreadyFilter = activeFilters[key].includes(value);
//     const _activeFilters = { ...activeFilters };
//     if (alreadyFilter) {
//       const filteredActiveFilters = _activeFilters[key].filter(
//         (item) => item !== value
//       );
//       _activeFilters[key] = filteredActiveFilters;
//       setActiveFilters(_activeFilters);
//     } else {
//       if (key === 'inch' && activeFilters['inch'].length > 0) {
//         Swal.fire('inch는 하나의 option만 선택 가능합니다');
//         return;
//       }

//       _activeFilters[key].push(value);
//       setActiveFilters(_activeFilters);
//     }
//   };

//   const handleFilter = () => {
//     setPage({ num: 1 });
//   };

//   // 검색 Controller
//   const [searchValue, setSearchValue] = useState('');
//   const onChangeSearchInput = (e) => {
//     setSearchValue(e.currentTarget.value);
//   };

//   const onClickSearch = () => {
//     setPage({ num: 1 });
//   };
//   // grid template 조절
//   const [gridTemplate, setGridTemplate] = useState('default'); // default, grid2, flat

//   return (
//     <>
//       {/* <div className="innerban">
//                 <img src="https://guswjd6476.speedgabia.com/thumb/ban1.jpg" />
//             </div> */}
//       <div className='shop main displaybox'>
//         <div className='shop_div'>
//           <div className='shop_sort'>
//             <LeftNavi />
//           </div>
//           <div className='shop_content'>
//             <div className='flex justify-between pr-[100px]'>
//               {!pathnum2 ? (
//                 <Crumb1 num={pathnum1} />
//               ) : (
//                 <Crumb2 num1={pathnum1} num2={pathnum2} />
//               )}

//               <div className='flex items-end'>
//                 <Space.Compact>
//                   <Search
//                     placeholder='제품명을 검색해주세요.'
//                     value={searchValue}
//                     onChange={onChangeSearchInput}
//                     onSearch={onClickSearch}
//                     style={{ width: 400 }}
//                   />
//                 </Space.Compact>
//               </div>
//             </div>

//             <div className='w-full flex gap-4 mb-4 pr-[100px]'>
//               <div>
//                 <Button onClick={toggleFilter}>
//                   {isVisableFilter ? '필터감추기' : '필터보이기'}
//                 </Button>
//               </div>
//               <div className='flex-1'>
//                 <Button className='w-1/5'>최근등록순</Button>
//                 <Button className='w-1/5'>판매량많은순</Button>
//                 <Button className='w-1/5'>랭킹추천순</Button>
//                 <Button className='w-1/5'>낮은가격순</Button>
//                 <Button className='w-1/5'>높은가격순</Button>
//               </div>
//               <div className='flex items-center'>
//                 <div className=''>
//                   <Button onClick={() => setGridTemplate('default')}>
//                     <TableOutlined />
//                   </Button>
//                 </div>
//                 <div>
//                   <Button onClick={() => setGridTemplate('grid2')}>
//                     <AppstoreOutlined />
//                   </Button>
//                 </div>
//                 <div>
//                   <Button onClick={() => setGridTemplate('flat')}>
//                     <PicLeftOutlined />
//                   </Button>
//                 </div>
//                 {/* </Button> */}
//               </div>
//             </div>

//             {/* 필터 컴포넌트 생성 */}
//             {isVisableFilter && (
//               <div className='w-full h-[200px] bg-[#c6d5fc] rounded-md flex gap-2 p-4'>
//                 <ul className='h-full  flex gap-2'>
//                   {['inch', 'brand', 'material', 'color'].map((item) => (
//                     <li className='w-[200px] h-full bg-white rounded-md border border-solid border-[#4b6cb7] '>
//                       <div className='h-[40px] px-4 py-2 border-b border-solid border-[#4b6cb7] text-lg font-bold '>
//                         {item}
//                       </div>
//                       <div
//                         className='p-4 '
//                         style={{
//                           height: 'calc(100% - 40px)',
//                           overflow: 'auto',
//                         }}
//                       >
//                         {inchOptions &&
//                           brandOptions &&
//                           materialOptions &&
//                           colorOptions &&
//                           getTargetOptions(item).map((option, index) => (
//                             <div
//                               className='cursor-pointer'
//                               style={{
//                                 backgroundColor: activeFilters[item].includes(
//                                   Object.values(option)[0]
//                                 )
//                                   ? '#e3e3e3'
//                                   : '',
//                               }}
//                               onClick={() =>
//                                 toggleActiveFilters(
//                                   item,
//                                   Object.values(option)[0]
//                                 )
//                               }
//                             >
//                               {Object.values(option)}
//                             </div>
//                           ))}
//                       </div>
//                     </li>
//                   ))}
//                 </ul>
//                 <div className='h-full'>
//                   <Button className='h-full' onClick={handleFilter}>
//                     필터 적용
//                   </Button>
//                 </div>
//               </div>
//             )}

//             {/* 제품's 박스 */}
//             <div>
//               <ProductList products={products} gridTemplate={gridTemplate} />
//             </div>
//             <div style={{ justifyItems: 'center' }}>
//               <Pagination
//                 defaultCurrent={1}
//                 total={totalElements}
//                 current={page.num}
//                 onChange={(page) => setPage({ num: page })}
//                 onShowSizeChange={(page, size) => setSize(size)}
//               />
//             </div>
//           </div>

//           {/* right bar 장바구니, 찜하기, 비교?, 최근본상품 */}
//           {/* <Fixedbox /> */}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Shop;
