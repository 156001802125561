import { useEffect, useState } from 'react';
import UploadThumb from '../uploadProduct/UploadThumb';
import Fileupload from '../uploadProduct/Fileupload';
import { Button, Input, DatePicker, Select, Flex, Modal } from 'antd';
import ReactQuills from '../uploadProduct/ReactQuills';
import { fetchDataPost, fetchDataPut } from '../../../service/instance';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const options = [
  { label: '회원가입', value: '회원가입' },
  { label: '배송', value: '배송' },
  { label: '겔제', value: '겔제' },
  { label: '취소 및 교환 A/S' },
  { label: '대량구매', value: '대량구매' },
  { label: '증빙서류', value: '증빙서류' },
  { label: '구매', value: '구매' },
];

/**
 * @typedef {Object} Event
 * @property {string} id
 * @property {string} title
 * @property {string} category
 * @property {string} thumb // 썸네일
 * @property {string} content
 * @property {string} startday
 * @property {string} lastday
 * @property {string} writer
 * @property {string} updatetime
 * @returns
 */

/**
 *
 * @param {Object} params
 * @param {Event} params.event
 * @param {boolean} params.isModalVisible
 * @param {Function} params.closeModal
 * @returns
 */
function EditEventModal({ event, isModalVisible, closeModal, R_updateEvent }) {
  const [fileList, setFileList] = useState([]);

  const [eventInfo, setEventInfo] = useState({
    category: null,
    title: '',
  });
  const [eventRange, setEventRange] = useState({
    startday: '',
    lastday: '',
  });

  const [value, setValue] = useState('');

  useEffect(() => {
    if (event) {
      setEventInfo({
        category: event.category,
        title: event.title,
      });
      setValue(event.content);
      setEventRange({
        startday: event.startday,
        lastday: event.lastday,
      });
      setFileList([
        {
          url: event.thumb,
        },
      ]);
    }
  }, [event]);

  const handleChange = (id, value) => {
    setEventInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleRangePicker = (value) => {
    setEventRange((prevState) => ({
      ...prevState,
      startday: value[0].format('YYYY-MM-DD'),
      lastday: value[1].format('YYYY-MM-DD'),
    }));
  };

  const api_uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('img', file.originFileObj);

    const response = await fetchDataPost('/api/images', formData);
    const uploadedImageUrl = response.data.url;
    return uploadedImageUrl;
    // console.log('🚀 ~ constapi_uploadImage= ~ result:', result);
  };

  const api_updateEvent = async (eventId, payload) => {
    return await fetchDataPut(`/api/event/${eventId}`, payload);
  };

  const updateEvent = async (eventId) => {
    if (fileList.length === 0) {
      alert('썸네일을 등록해주세요');
      return;
    }
    if (fileList.length > 1) {
      alert('썸네일은 1개만 등록 가능합니다.');
      return;
    }

    let thumbUrl = event.thumb;
    if (thumbUrl !== fileList[0]?.url) {
      thumbUrl = await api_uploadImage(fileList[0]);
    }

    const payload = {
      title: eventInfo.title,
      startday: eventRange.startday,
      lastday: eventRange.lastday,
      content: value,
      thumbUrl,
      category: eventInfo.category,
    };

    const response = await api_updateEvent(eventId, payload);
    console.log('🚀 ~ addEvent ~ response:', response);
    if (response.status === 200) {
      alert('이벤트 수정이 완료되었습니다.');
      closeModal();
      R_updateEvent({ ...payload, id: eventId, thumb: thumbUrl });
    }
  };

  const rangePickerValue =
    eventRange.startday && eventRange.lastday
      ? [dayjs(eventRange.startday), dayjs(eventRange.lastday)]
      : [(dayjs(event?.startday), dayjs(event?.lastday))];

  return (
    <Flex>
      <Modal
        title='이벤트 수정'
        open={isModalVisible}
        onOk={() => updateEvent(event.id)}
        onCancel={closeModal}
        okText='수정'
        cancelText='취소'
        width={{
          xs: '90%',
          sm: '90%',
          md: '90%',
          lg: '90%',
          xl: '90%',
          xxl: '90%',
        }}
      >
        {event && (
          <div className='UploadProduct'>
            <Select
              placeholder='카테고리'
              value={eventInfo.category}
              onChange={(value) => handleChange('category', value)}
              className='productSelect'
              options={options}
            />
            <RangePicker
              value={rangePickerValue}
              onChange={handleRangePicker}
            />
            <UploadThumb fileList={fileList} setFileList={setFileList} />

            <Input
              className='productSelect con'
              placeholder='게시물 제목'
              value={eventInfo.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
            <ReactQuills value={value} setValue={setValue} />
          </div>
        )}
      </Modal>
    </Flex>
  );
}

export default EditEventModal;
