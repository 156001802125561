
import { fetchData, fetchDataWithToken, fetchDataPost, fetchDataPut, fetchDataDelete } from "./instance";

// 장바구니 추가 함수 
const addCart = async (num, userId, counter) => {
  const data = [{ id: num, count: counter }];
  return fetchDataPost('/api/cart', { data, userId });
};

// 장바구니 추가 함수 (복수)
const addsCart = async (data, userId) => {
  return fetchDataPost('/api/cart', { data, userId });
};


const getCart = async (userId) => {
  return fetchDataWithToken(`/api/users/${userId}/cart`);
};
// const getCart = async (userId) => {
//   return fetchDataWithToken(`/api/cart`, { userId });
// };

const deleteCart = async ({ userId, productIds }) => {
  if (!userId || !productIds.length) {
    throw new Error("userId와 productIds가 필요합니다.");
  }

  const params = {
    userId,
    ids: productIds.join(","), // 숫자 배열을 "1,2,3" 형태의 문자열로 변환
  };

  return fetchDataDelete('/api/cart', {}, params);
};

const productdetail = async (id) => {
  return fetchData(`/api/products/${id}`);
};

const getproduct = async (cate, none) => {
  return fetchData('/api/products', { cate, none });
};

const Allproductdetail = async (filter) => {
  return fetchData('/api/products', { filter });
};
const Allgetproduct = async () => {
  return fetchData('/api/products');
};
const updatenames = async (id, category, subcategory, pName, pquantity, pPrice, inch, material, brand, color) => {
  return fetchData('/api/updatename', { id, category, subcategory, pName, pquantity, pPrice, inch, material, brand, color });
};

// 추천상품
const recommendlist = async (subcate, num) => {
  return fetchData('/api/product-recommend', { subcate, num });
};

// 소팅 프로덕트 가져오기 
const getSort = async (sort, path) => {
  return fetchData('/api/product-filter', { sort, path });
};

const getAllItem = async (path) => {
  if ('notice' === path) {
    return fetchData(`/api/notice`);
  } else if ('qna' === path) {
    return fetchData(`/api/qna`);
  } else if ('faq' === path) {
    return fetchData(`/api/faq`);
  } else {
    console.error(`잘못된 요청입니다. getAllItem(path=${path}))`);
    // return fetchData('/api/getAllItem', { path });
  }
};

// 특정 게시물 불러오기
// const getItemcon = (num,path)=>{
//   return axios.get('/api/getItemcon', {
//     params: {
//     num,path
//     },
//   });
// }
const getItemcon = async (num, path) => {
  console.log('num:', num, 'path', path)
  if ('notice' === path) {
    return fetchData(`/api/notice/${num}`);
  } else if ('qna' === path) {
    return fetchData(`/api/qna/${num}`);
  } else if ('event' === path) {
    return fetchData(`/api/event/${num}`);
  } else {
    console.error(`잘못된 요청입니다. getItemcon(num=${num}, path=${path}))`);
    // return fetchData('/api/getItemcon', { num, path });
  }
};
// 특정 게시물 공지사항 불러오기
// const idQna = (num)=>{
//   return axios.get('/api/idQna', {
//     params: {
//     num
//     },
//   });
// }
const idQna = async (num) => {
  return fetchData('/api/idQna', { num });
};
// 공지사항 글 작성하기 
// const Wnotice = (title,content,writer,category)=>{
//   return axios.get('/api/writenotice', {
//     params: {
//       title,content,writer,category
//     },
//   });
// }
const Wnotice = async (title, content, writer, category) => {
  // return fetchData('/api/writenotice', { title, content, writer, category });
  return fetchDataPost('/api/notice', { title, content, writer, category });
};
// qna 글 작성하기 
// const Wqna = (title,content,writer,category)=>{
//   return axios.get('/api/writeqna', {
//     params: {
//       title,content,writer,category
//     },
//   });
// }
const Wqna = async (title, content, writer, category) => {
  // return fetchData('/api/writeqna', { title, content, writer, category });
  return fetchDataPost('/api/qna', { title, content, writer, category });
};
// qna 답변글 작성하기 
// const Wqnaanswer = (content,writer,num)=>{
//   return axios.get('/api/writeqnaanswer', {
//     params: {
//       content,writer,num
//     },
//   });
// }

// fixme: 답변달 때 writer 가 아예 안넘어옴. 이 부분 확인 필요
const Wqnaanswer = async (content, writer, num) => {
  // return fetchData('/api/writeqnaanswer', { content, writer, num });
  return fetchDataPut(`/api/qna/${num}/answer`, { content, writer });
};
// faq 글 작성하기 
// const Wfaq = (title,content,writer,category)=>{
//   return axios.get('/api/writefaq', {
//     params: {
//       title,content,writer,category
//     },
//   });
// }
const Wfaq = async (title, content, writer, category) => {
  // return fetchData('/api/writefaq', { title, content, writer, category });
  return fetchDataPost('/api/faq', { title, content, writer, category });
};
// 이전 글 가져오기
// const getPrevNext = (num,id)=>{
//   return axios.get('/api/prevNext', {
//     params: {
//     num,
//     id
//     },
//   });
// }
const getPrevNext = async (num, id) => {
  if ('notice' === num) {
    return fetchData(`/api/notice/${id}/prevNext`);
  } else if ('qna' === num) {
    return fetchData(`/api/qna/${id}/prevNext`);
  } else if ('event' === num) {
    return fetchData(`/api/event/${id}/prevNext`);
  } else {
    console.error(`잘못된 요청입니다. getPrevNext(num=${num}, id=${id}))`);
    // return fetchData('/api/prevNext', { num, id });
  }
};

const updateItem = async (title, content, category, id, path) => {
  if ('notice' === path) {
    return fetchDataPut(`/api/notice/${id}`, { title, content, category });
  } else if ('qna' === path) {
    return fetchDataPut(`/api/qna/${id}`, { title, content, category });
  } else if ('event' === path) {
    return fetchDataPut(`/api/event/${id}`, { title, content, category });
  } else {
    console.error(`잘못된 요청입니다. updateItem(path=${path}, id=${id}))`);
    // return fetchData('/api/updateItem', { title, content, category, id, path });
  }

};
// 메인 데이터 가져오기 
// const getmain = ()=>{
//   return axios.get('/api/getmain');
// }

const getmain = async () => {
  return fetchData('/api/maincontrol');
};
// 메인업데이트 
// const updatemain = (a1,a2,a3,a4,a5,a6,a7,a8,b1,b2,b3,b4,b5,b6,b7,b8)=>{
//   return axios.get('/api/updatemain', {
//     params: {
//       a1,a2,a3,a4,a5,a6,a7,a8,b1,b2,b3,b4,b5,b6,b7,b8
//     },
//   });
// }
const updatemain = async (a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, b1, b2, b3, b4, b5, b6, b7, b8, b9, b10) => {
  return fetchDataPut('/api/maincontrol', { a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, b1, b2, b3, b4, b5, b6, b7, b8, b9, b10 });
};

const getiot = async () => {
  return fetchData('/api/iot-recent');
};

const uploadproduct = async (categoryId, subcategoryId,
  pName, pquantity, pCost, dcrate, moq, prepare, inch, color, material, brand, pDetail, urls) => {
  return fetchDataPost('/api/products', {
    categoryId, subcategoryId,
    pName, pquantity, pCost, dcrate, moq, prepare, inch, color, material, brand, pDetail, urls
  });
};
const getevent = async () => {
  return fetchData('/api/event');
};
const getsubevent = async (id) => {
  // return fetchData('/api/getsubevent', { id });
  return fetchData(`/api/event/${id}`);
};



export { addCart, getCart, deleteCart, productdetail, getproduct, addsCart, recommendlist, getSort, getAllItem, Wnotice, getItemcon, idQna, Wqna, Wfaq, Wqnaanswer, getPrevNext, updateItem, Allgetproduct, updatemain, getmain, getiot, Allproductdetail, updatenames, uploadproduct, getevent, getsubevent };

