import {
  AppstoreOutlined,
  ExclamationCircleFilled,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Menu, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logow from './Image/logow.png';
import { useAuthStore } from './store/useAuthStore';
import './style/mstyle.css';
import { CATEGORY, MAIN_CATEGORY } from './utils/constant';
const { confirm } = Modal;

const MHeaer = ({ sb, setSb, back }) => {
  const userInfo = useAuthStore((state) => state.userInfo);
  const isLogin = useAuthStore((state) => state.isLogin);

  // const isAdmin = userInfo.value.uGrade === 1;

  const [navi, setNavi] = useState(false);
  const addedKeys = [];
  const newItems = MAIN_CATEGORY.map((item) => {
    const key = `메인카테고리-mobile-${item.category}`;

    if (addedKeys.includes(key)) {
      // 중복된 key일 경우 무시
      return null;
    }
    addedKeys.push(key);

    return {
      label: <Link to={item.link}>{item.label}</Link>,
      key,
      path: key,
      icon: <AppstoreOutlined />,
    };
  });
  // const navigate = useNavigate();
  const location = useLocation();
  const pathKeys = location.pathname.split('/').filter(Boolean);
  const pathnum1 = location.pathname.split('/')[1];
  const pathnum2 = location.pathname.split('/')[2];
  const pathnum3 = location.pathname.split('/')[3];
  const returns = pathnum3
    ? `/${pathnum1}/${pathnum2}`
    : pathnum2
      ? `/${pathnum1}`
      : '/';
  const showConfirm = () => {
    confirm({
      title: '로그아웃 하시겠습니까?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('recent');
        window.location.href = '/';
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const el = useRef();
  useEffect(() => {
    const handlemenu = (e) => {
      if (navi && el.current && !el.current.contains(e.target)) {
        setNavi(false);
      }
    };
    document.addEventListener('mousedown', handlemenu);
    return () => {
      document.removeEventListener('mousedown', handlemenu);
    };
  }, [navi]);
  return (
    <div className='mobile_btn_wrap' ref={el}>
      {/* {back == true ? (
        <div className='backbtn'>
          <Link to={returns}>
            {' '}
            <LeftOutlined />{' '}
          </Link>
        </div>
      ) : (
        ''
      )} */}
      <Button className={'mobile_btn'} onClick={() => setNavi(!navi)}>
        {navi ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </Button>

      {navi ? (
        <div className='mnavigation '>
          {/* <div className='search_bar m'>
            <AllSearchComponent setSb={setSb} sb={sb} />
          </div> */}
          <Menu
            onClick={(e) => setNavi(false)}
            selectedKeys={[...pathKeys, `/${pathKeys[0]}`]}
            mode='inline'
            items={newItems}
          />
          <ul className='header_top_box  m'>
            {!isLogin ? (
              <li>
                <Link to='/Login'>로그인</Link>
              </li>
            ) : (
              ''
            )}
            {!isLogin ? (
              <li style={{ marginLeft: '20px' }}>
                <Link to='/Signup'>회원가입</Link>
              </li>
            ) : (
              ''
            )}

            <li onClick={(e) => setNavi(false)} style={{ marginLeft: '20px' }}>
              <Link to='/customer-service'>고객센터</Link>
            </li>
            <li style={{ marginLeft: '20px' }}>
              <Link
                to={!isLogin ? '/Login' : '/Mypage'}
                className='function_head_box login'
              >
                <div>마이페이지</div>
              </Link>
            </li>
            {isLogin ? (
              <li onClick={showConfirm} style={{ marginLeft: '20px' }}>
                <a>로그아웃</a>
              </li>
            ) : (
              ''
            )}
          </ul>

          {userInfo && userInfo.uGrade == 1 ? (
            <div className='mobileadmin'>
              <Link to='/Admin'>관리자 입니다</Link>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      <Link onClick={(e) => setNavi(false)} to='/' className='logo'>
        <img src={logow} alt='logo' />
      </Link>
      <div onClick={(e) => setNavi(false)} className='shopcart'>
        <Link to={'/Cart'} className='function_head_box cart'>
          <ShoppingCartOutlined />
        </Link>
      </div>
    </div>
  );
};
export default MHeaer;
