import React, { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getCategoryId } from '../../../utils/function';
import { useQuery } from 'react-query';
import { reactQueryFn } from '../../../service/instance';
import { CATEGORY } from '../../../utils/constant';

function LeftNavi() {
  const location = useLocation();
  const pathName = location.pathname.split('/')[2];
  const pathName2 = location.pathname.split('/')[3];
  const [subCategorys, setSubCategorys] = useState([]);
  const cagegoryId = getCategoryId(pathName);

  const { data } = useQuery({
    queryKey: ['category-depth2'],
    queryFn: () => reactQueryFn('api/category?depth=2'),
  });

  const isShopPage = CATEGORY.some((v) => v.category === pathName);

  useEffect(() => {
    if (data) {
      const currentCategorys = data.filter(
        (item) => item.categoryId === cagegoryId
      );
      const _subCategorys = currentCategorys.map((item) => item.subcategory);
      setSubCategorys(_subCategorys);
    }
  }, [data, cagegoryId]);

  return (
    <div className='shop_sort'>
      {isShopPage && (
        <div className='sortTitle'>
          <span>CATEGORIES</span>
        </div>
      )}
      <ul className='sort_navi'>
        {subCategorys.length > 0 &&
          subCategorys.map((value, index) => (
            <li
              className={`${pathName2 == value ? 'active snavili' : 'snavili'} pl-[20px]`}
              key={`left-navi-${index}`}
            >
              <Link to={`/shop/${pathName}/${value}`}>
                {value.toUpperCase()}
              </Link>

              <div
                className={pathName2 == value ? 'active triangle' : 'none'}
              ></div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default LeftNavi;
