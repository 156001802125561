import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Pagetitle from '../components_btn/Pagetitle';
import { getevent } from '../../service/product';
import { usePopup } from '../../PopupContext';
function Event({ setBack }) {
  const location = useLocation();
  const pathnum1 = location.pathname.split('/')[1];

  const [elist, setElist] = useState('');
  useEffect(() => {
    setBack(true);
    getevent().then(function (response) {
      setElist(response.data);
    });
  }, [pathnum1]);

  return (
    <div className='Event displaybox main'>
      <Pagetitle
        value={'EVENT'}
        svalue={'니즈로봇이 진행한 이벤트 입니다'}
        center='true'
      />
      <div className='eventpgCon'>
        {elist &&
          elist.map((value) => (
            <Link
              to={`event?&productid=${value.id}`}
              className='eventpgWrap'
              key={value.id}
            >
              <div className='maineventimg'>
                <img src={value.thumb} />
              </div>
              <div className='eventT'>{value.title}</div>
              <div className='eventD'>
                <div>{value.startday.split(' ')[0]}</div>
                <div>~</div>
                <div>{value.lastday.split(' ')[0]}</div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default Event;
