/* eslint-disable react/prop-types */
import { Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  categoryOptions,
  colorOption,
  materialOption,
} from '../../../service/options';
import { CATEGORY } from '../../../utils/constant';
import useFlatCategorys from '../../../utils/useFlatCategorys';

function Productinfo({ productinfo, setProductInfo }) {
  const handleChange = (id, value) => {
    setProductInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // subcategory fetch
  const { flatCategorys } = useFlatCategorys();
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  useEffect(() => {
    if (flatCategorys) {
      const categoryLabel = CATEGORY.find(
        (item) => item.id === productinfo.categoryId
      )?.category;
      const subcategoryOptions =
        flatCategorys &&
        categoryLabel &&
        flatCategorys[categoryLabel].map((item) => {
          return {
            value: item.id,
            label: item.subcategory,
          };
        });
      setSubcategoryOptions(subcategoryOptions);
    }
  }, [flatCategorys, productinfo.categoryId]);

  return (
    <div className='Productinfo productSelectwrap'>
      <div className='cateinfo'>
        <Select
          placeholder='카테고리'
          value={productinfo.categoryId}
          onChange={(value) => {
            handleChange('categoryId', value);
          }}
          className='productSelect'
          options={categoryOptions}
        />
        <Select
          placeholder='세부카테고리'
          value={productinfo.subcategoryId}
          onChange={(value) => handleChange('subcategoryId', value)}
          className='productSelect'
          options={subcategoryOptions}
        />
        <Input
          className='nameinput productSelect'
          placeholder='제품명'
          value={productinfo.pName}
          onChange={(e) => handleChange('pName', e.target.value)}
        />
        <InputNumber
          className='productSelect'
          placeholder='재고'
          value={productinfo.pquantity}
          onChange={(value) => handleChange('pquantity', value)}
        />
        <Input
          className='productSelect'
          placeholder='가격입력'
          value={productinfo.pCost}
          onChange={(e) => handleChange('pCost', e.target.value)}
        />
        <InputNumber
          className='productSelect'
          placeholder='할인율'
          value={productinfo.dcrate}
          onChange={(value) => handleChange('dcrate', value)}
        />
        <InputNumber
          className='productSelect'
          placeholder='moq'
          value={productinfo.moq}
          onChange={(value) => handleChange('moq', value)}
        />
        <InputNumber
          className='productSelect'
          placeholder='준비기간'
          value={productinfo.prepare}
          onChange={(value) => handleChange('prepare', value)}
        />
      </div>
      <div className='cateinfo'>
        <InputNumber
          className='productSelect'
          placeholder='인치'
          value={productinfo.inch}
          onChange={(value) => handleChange('inch', value)}
        />
        <Input
          placeholder='색상'
          value={productinfo.color}
          onChange={(e) => handleChange('color', e.target.value)}
          className='productSelect'
          // options={colorOption}
        ></Input>
        <Input
          placeholder='소재'
          value={productinfo.material}
          onChange={(e) => handleChange('material', e.target.value)}
          className='productSelect'
          // options={materialOption}
        ></Input>
        <Input
          placeholder='제조사'
          value={productinfo.brand}
          onChange={(e) => handleChange('brand', e.target.value)}
          className='brand productSelect'
        />

        <Input
          value={productinfo.pDetail}
          onChange={(e) => handleChange('pDetail', e.target.value)}
          className='productSelect de'
          showCount
          placeholder='세부사양'
          maxLength={300}
        />
      </div>
    </div>
  );
}

export default Productinfo;
