import { fetchData, dData, fetchDataPost, fetchDataPut } from "./instance";

const getPass = async (password, userId) => {
  return fetchDataPost(`/api/users/${userId}/password-check`, { password });
};
const userlists = async () => {
  return fetchData('/api/users');
};
const getUser = async (userId) => {
  return fetchData(`/api/users/${userId}`);
};
const getAddress = async (userId) => {
  return fetchData('/api/address', { userId });
};
const addAddress = async (userId, address, addtype, addressnum, subaddress, uName, uPhone) => {
  return fetchDataPost('/api/address', { userId, address, addtype, addressnum, subaddress, uName, uPhone });
};

// fixme: userId 를 추가로 받아서 넘겨주도록 고도화 되어야 함.
const selectAddress = async (num, userId) => {
  if (!userId) {
    throw new Error("userId가 필요합니다.");
  }
  return fetchDataPut(`/api/address/${num}/selected`, { userId });
};
const logins = async (userId, uPassword) => {
  return fetchDataPost('/api/auth/login', { userId, uPassword });
};
const useridchecks = async (userId) => {
  return fetchData('/api/auth/check-userid', { userId });
};
const mailing = async (email) => {
  return fetchDataPost('/api/auth/email-verification', { email });
};
const getOrderlist = async (userId) => {
  return fetchData(`/api/users/${userId}/buylist`);
};
const getAllOrderlist = async () => {
  return fetchData('/api/buylist');
};
const getAfterbuylist = async (userId) => {
  return fetchData('/api/afterbuy', { userId });
};
const getThisAfterbuylist = async (num) => {
  return fetchData('/api/afterbuy', { num });
};
const addAfeterbuylist = async (num, userId, title, rate, content) => {
  return fetchDataPost('/api/afterbuy', { num, userId, title, rate, content });
};
// 배송조회
const deliveryApi = async (carriers, num) => {
  return dData(carriers, num);
};
// 운송장 등록
const addDeliver = async (state, dNum, carrier, id) => {
  return fetchDataPut(`/api/buylist/${id}`, { state, dNum, carrier });
};
// 찜목록 가져오기
const getNeeds = async (userId) => {
  return fetchData('/api/needs', { userId });
};
// 찜목록 지우기
// fixme: DELETE /api/needs/${num} 경로로 요청하도록 수정 필요
// NOTE: 아래 deleteNeeds function 을 호출하는 FE 코드 버그 파악 필요
const deleteNeeds = async (num) => {
  return fetchData('/api/deleteNeeds', { num });
};
export { getPass, userlists, logins, useridchecks, mailing, getUser, getAddress, addAddress, selectAddress, getOrderlist, getAfterbuylist, addAfeterbuylist, getThisAfterbuylist, deliveryApi, getAllOrderlist, addDeliver, getNeeds, deleteNeeds }
