import React from 'react';
import { useState, useEffect } from 'react';
import Pagetitle from '../components_btn/Pagetitle';
import { getevent } from '../../service/product';
import { Mainlinkto } from './Mainfunction';
import { Link } from 'react-router-dom';

function Mainevent() {
  const [eventlist, setEventlist] = useState();
  useEffect(() => {
    getevent().then(function (response) {
      setEventlist(response.data);
    });
  }, []);

  // return (
  //   <div className='iotallcon'>
  //     <Pagetitle value={'진행중인 이벤트'} main={true} />
  //     {/* <Mainlinkto cates={cates} num={6} value={'이벤트'} color={'b'} /> */}
  //     <div className='iotcontains'>
  //       {eventlist &&
  //         eventlist.slice(0, 2).map((value) => (
  //           <div className='maineventwrap' key={value.id}>
  //             <div className='maineventimg'>
  //               <img src={value.thumb} />
  //             </div>
  //             <div>
  //               <p>{value.title}</p>
  //             </div>
  //           </div>
  //         ))}
  //     </div>
  //   </div>
  // );
  return (
    <div className='iotallcon'>
      <Pagetitle value={'진행중인 이벤트'} main={true} />
      <div className='iotcontains'>
        {eventlist &&
          eventlist.slice(0, 4).map((value) => (
            <Link
              to={`/EVENT/event?&productid=${value.id}`}
              key={value.id}
              className='maineventwrap' // 기존 CSS 적용
              style={{ display: 'block' }} // 혹은 inline-block으로 조절
            >
              <div className='maineventimg'>
                <img src={value.thumb} alt={value.title} />
              </div>
              <div>
                <p>{value.title}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default Mainevent;
